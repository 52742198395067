import React, { useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode';
import Sample from './container/Sidebar'
import Login from './auth/Login'
import Signup from './auth/Signup'
import { Navigate, Route,BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom'
import Overview from './components/singlePageAccount/Overview'
import Dashboard from './pages/Dashboard'
import Users from "./components/settings/Users"
import Sidebar from './container/Sidebar'
import Account from './pages/Account'
import Success from './pages/Success'
import Request from './pages/Request'
import SingleAccount from './components/singlePageAccount/SingleAccount'
import Settings from './pages/Settings'
import Onboard from './components/onboarding/Onboard'
import AccountField from './components/settings/AccountTeams'
import Verify from './auth/Verify'
import AccountDetails from './components/settings/AccountDetails'
import AccountTeams from './components/settings/AccountTeams'
import Health from './components/settings/Health'
import Product from './components/settings/Product'
import UploadAccData from './components/settings/UploadAccData'
import ForgotPassword from './auth/ForgotPass'
import Relogin from './auth/Relogin';
import Analytics from './pages/Analytics';
import AccountInfo from './components/settings/AccountInfo';
import ContactUpload from './components/settings/ContactUpload';
import ContactInfo from './components/settings/ContactInfo';

const AppContent = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate =useNavigate();

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  }

  function deleteCookie(name) {
    const isLocalhost = window.location.hostname === "localhost";
    const cookieDomain = isLocalhost ? "localhost" : "dashboard.triumphiq.com";
    document.cookie = `${name}=; path=/; domain=${cookieDomain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=Strict`;
  }
  
  const token = getCookie('authToken');

  if (token) {
    localStorage.setItem("authToken", token);
  } 
 
  if(localStorage.getItem("authToken")!==null){
    deleteCookie('authToken');
  }

  const checkTokenExpiry = () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setIsAuthenticated(false);
      return;
    }

    try {
      const { exp } = jwtDecode(token); 
      const currentTime = Math.floor(Date.now() / 1000); 

      if (exp && currentTime >= exp) {
        localStorage.removeItem('authToken'); 
      navigate("/login")
      } else {
        setIsAuthenticated(true); 
      }
    } catch (error) {
      console.error('Invalid token:', error);
      localStorage.removeItem('authToken'); 
      setIsAuthenticated(false);
    }
  };

  
  useEffect(() => {
    checkTokenExpiry();
    
    const interval = setInterval(() => {
      checkTokenExpiry();
    }, 60000); // Check every 60 seconds
    
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  
  useEffect(() => {
    const hostname = window.location.hostname;
    const isOrgSubdomain = hostname.split('.')[0] !== 'dashboard';

    if(localStorage.getItem('authToken')){
      if (isOrgSubdomain && window.location.pathname === '/') {
       navigate('/analytics');
      } 
    }
  
    if (!isAuthenticated) {
      if (isOrgSubdomain && window.location.pathname === '/') {
        window.location.href = "/login"
      } else if (!isOrgSubdomain && window.location.pathname === '/') {
        navigate('/signup');
      }
    } 
   
  }, [isAuthenticated, navigate]);
  

  return (
    <>
    
      {isAuthenticated ? (
        <Routes>
        <Route exact path="/accounts/:id" element={<SingleAccount/>} />
        <Route exact path="/accounts" element={<Account/>} />
        <Route exact path="/success" element={<Success/>} />
        <Route exact path="/request" element={<Request/>} />
        <Route exact path="/settings" element={<Settings/>} />
        <Route exact path="/settings/users" element={<Users/>} />
        <Route exact path="/onboard" element={<Onboard/>} />
        <Route exact path="/settings/accountTeams" element={<AccountTeams/>} />
        <Route exact path="/settings/accountDetails" element={<AccountDetails/>} />
        <Route exact path="/settings/AccountDataUpload" element={<UploadAccData/>} />
        <Route exact path="/settings/health" element={<Health/>} />
        <Route exact path="/settings/product" element={<Product/>} />
        <Route exact path="/re-login" element={<Relogin/>} />
        <Route exact path="/analytics" element={<Dashboard/>} /> 
        <Route exact path="/settings/accountInfo" element={<AccountInfo/>} />
        <Route exact path="/settings/contactUpload" element={<ContactUpload/>} />
        <Route exact path="/settings/contactInfo" element={<ContactInfo/>} />
        <Route  exact path="*" element={<Navigate to="/login"/>} />
        </Routes>
       ):(
         <Routes>
        {/* <Route exact path="/accounts/:id" element={<SingleAccount/>} />
        <Route exact path="/accounts" element={<Account/>} />
        <Route exact path="/success" element={<Success/>} />
        <Route exact path="/request" element={<Request/>} />
        <Route exact path="/settings" element={<Settings/>} />
        <Route exact path="/settings/users" element={<Users/>} />
        <Route exact path="/onboard" element={<Onboard/>} />
        <Route exact path="/settings/accountTeams" element={<AccountTeams/>} />
        <Route exact path="/settings/accountDetails" element={<AccountDetails/>} />
        <Route exact path="/settings/AccountDataUpload" element={<UploadAccData/>} />
        <Route exact path="/settings/health" element={<Health/>} />
        <Route exact path="/settings/product" element={<Product/>} />
        <Route exact path="/re-login" element={<Relogin/>} />
        <Route exact path="/analytics" element={<Dashboard/>} /> 
        <Route exact path="/settings" element={<Settings/>} />
        <Route exact path="/settings/accountInfo" element={<AccountInfo/>} />
        <Route exact path="/settings/users" element={<Users/>} />
        <Route exact path="/settings/contactUpload" element={<ContactUpload/>} />
        <Route exact path="/settings/contactInfo" element={<ContactInfo/>} />

        <Route exact path="/accounts" element={<Account/>} />
        <Route  exact path="*" element={<Navigate to="/login"/>} /> */}
   {/* testing above  */}
        <Route exact path="/signup" element={<Signup/>} />
        <Route exact path="/re-login" element={<Relogin/>} />
        <Route exact path="/login" element={<Login/>} />
        <Route exact path="/verify-account" element={<Verify/>} />  
        <Route exact path="/forgotpassword" element={<ForgotPassword/>} />
        {/* <Route exact path="/onboard" element={<Onboard />} /> */}
        {/* <Route  exact path="/" element={<Navigate to="/signup"/>} /> */}
        </Routes>
       )
      }
  
    </>
  )
}

const App =()=>{
  return(
    <Router>
    <AppContent />
  </Router>
  )
}
export default App
