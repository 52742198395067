import React, { useEffect, useRef, useState } from 'react';
import { FaSearch, FaKey, FaTrashAlt, FaFilter } from 'react-icons/fa';
import { BsPersonPlus } from 'react-icons/bs';
import Sidebar from '../../container/Sidebar';
import Settingbar from '../../container/Settingbar';
import { jwtDecode } from "jwt-decode";
import { IoArrowBack, IoArrowBackCircleOutline, IoCloseCircleSharp } from 'react-icons/io5';
import { MdArrowBackIosNew, MdCloudUpload } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { CiFilter } from 'react-icons/ci';
import axios from 'axios';
import { BiSearchAlt } from 'react-icons/bi';

const UsersAndTeam = () => {
   const [addUser, setAddUser]= useState(false)
   const [addcsv, setAddcsv]= useState(false)
  const [userData, setUserData] = useState(null);
  const [allUsers, setAllUsers]= useState(null)
  const [name, setName]= useState('')
  const [email, setEmail]= useState('')
  const [role, setRole]= useState('')
  const [searchQuery, setSearchQuery] = useState("")
  const [organisationCname, setOrganisationCname]= useState(window.location.hostname.split('.')[0]);

  const handleOnclick = ()=>{
    setAddUser(!addUser);
  }
  const handlecsvclick = ()=>{
    setAddcsv(!addcsv);
  }

  const getAllUsers = async () => {
    const token = localStorage.getItem('authToken') 
    try {
      const response = await fetch(`https://be.triumphiq.com/users?organisationCname=${window.location.hostname.split('.')[0]}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setAllUsers(result);
      console.log('got it', result);
    } catch (error) {
     console.error('error', error)
    } 
  };

 // get all users
  useEffect(() => {
    getAllUsers();
  }, []);


  // add user
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://be.triumphiq.com/users",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
          body: JSON.stringify({
            email,
            name,
            role,
            organisationCname,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        alert("Success");
      setAddUser(!addUser);
    getAllUsers();
       console.log('Sucessfully added', data);
      } else {
        console.error("SignUp failed", data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fileInputRef = useRef(null);


//upload file
  const handleFileChange = async () => {
    const file = fileInputRef.current.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const token = localStorage.getItem('authToken');

      try {
        const response = await axios.post(`https://be.triumphiq.com/users/bulk?file=${file}`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('File uploaded successfully:', response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.log('No file selected');
    }
  };

  const filteredUsers = allUsers?.filter((user) =>
    user.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Sidebar>
    <div className=" bg-white relative h-screen">
      <div className="flex items-center justify-between mb-4 pt-[26px] px-6">
        <div className='flex gap-2 items-center'>
          <Link to="/settings">
          <IoArrowBackCircleOutline size={24} />
        </Link>
        <h2 className="text-2xl ">Users</h2>
        </div>
        </div>
        <div className='mx-6 bg-white mt-6 rounded-md shadow-md border-gray-200 border mb-2'>
        <div className='flex items-center justify-between h-14 border-b-2 '>
        <h2 className='text-base px-7 py-2'>Users</h2>

          <div className='flex gap-4 items-center px-7'>
          <div className="mb-1 relative">
            <BiSearchAlt color="gray"
            className="absolute top-2 left-1" />

              <input
                type="text"
                placeholder="Search by username"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border border-gray-300 rounded-md py-1 px-6 w-64 text-sm focus:outline-gray-400"
              />
            </div>
          <p className='text-sm'>{`Total Users: ${allUsers?.length}`}</p>
          <div className='flex gap-4 items-center'>
        <button className="flex items-center px-4 py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handleOnclick}>
          <BsPersonPlus className="mr-2" /> Add User
        </button>
        {/* <button className="flex items-center px-4 py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handlecsvclick}>
          <BsPersonPlus className="mr-2" /> Upload in Bulk
        </button> */}
        </div>
          </div>
      </div>
      <div className="bg-white shadow rounded-md overflow-x-auto p-4">
        <table className="min-w-full table-auto">
          <thead className=" text-sm text-gray-600">
            <tr className=''>
              <th className=" py-2 px-3 text-left w-[380px]">User Name</th>
              <th className=" text-left py-2 px-6 w-[380px]">Email</th>
              <th className=" text-left py-2 px-3">Role</th>
              <th className="text-left py-2 pl-3">Status</th>
              <th className=" text-right py-2 pr-6">Actions</th>
            </tr>
          </thead>
          <tbody>
           {filteredUsers?.map((data, index)=>{
            return (
              <tr className=" text-sm" key={index}>
               
                <td className="py-2 px-3 text-left flex items-center">
                {data?.username}
                </td>
                <td className="py-2 px-6 text-left">
                  <span className=" py-1  rounded-full" >
                  {data?.username}
                  </span>
                </td>
                <td className='py-2 px-3 text-left capitalize'> {data?.authorities[0]?.authority=== "CSM"? data.authorities[0].authority: data.authorities[0].authority.toLowerCase()}</td>
                <td className="py-2 px-3 text-left text-gray-500 text-sm">{localStorage.getItem('authToken')? <p>active</p>: <p>Inactive</p>}</td>
                <td className="py-2 pr-3 text-left flex justify-end items-center space-x-4 text-gray-500 gap-1 text-xs cursor-pointer">
                 
                  <FaTrashAlt className="cursor-pointer hover:text-gray-700" title="Delete" /> Remove
                </td>
              </tr> 
          )
         })}
          </tbody>
        </table>
      </div>
      {/* add user popup  */}
      <div className={` ${addUser?'block':'hidden'}  inset-0 flex items-center justify-center`}>
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
      <div class="border-2 border-gray-500 bg-white rounded-md p-4 h-[220px] w-[700px]">
      <IoCloseCircleSharp size={24} onClick={handleOnclick} className='cursor-pointer float-end' />
      <h2 className='text-center font-semibold pt-4'>Add User</h2>
      <form action="" onSubmit={handleOnSubmit}>
      <div className='flex flex-wrap justify-around p-2 pt-6 text-sm'>
        <div className='w-24 flex flex-col justify-center items-center'>
          <h2>Name</h2>
          <input type="text" className='border-2 w-42 rounded-md h-8 focus:outline-gray-400  px-1 border-gray-300' placeholder='Enter name' value={name} onChange={(e)=>setName(e.target.value)} required/>
        </div>
        <div className='w-24 flex flex-col justify-center items-center'>
          <h2>Email Address</h2>
          <input  className='border-2 rounded-md h-8 w-56 px-1 border-gray-300 focus:outline-gray-400' placeholder='Email' id='email' type="email" value={email} required  onChange={(e) => setEmail(e.target.value)}/>
        </div>
        <div className='w-24 flex flex-col justify-center items-center'>
          <h2>Role</h2>
          <select name="role" id="role" className='w-28 border-2 border-gray-300 rounded-md focus:outline-gray-400' value={role} required onChange={(e) => setRole(e.target.value)}>
          <option value="" disabled>Select </option>
            <option value="CSM">CSM</option>
            <option value="admin">Admin</option>
          </select>
          {/* <input type="text" className='border'value={role} onChange={(e)=>setRole(e.target.value)}/> */}
        </div>
      </div>
      <div className='flex  justify-end px-10 pt-5'>
      <button className='flex bg-blue-500 px-8 text-white rounded-md py-1' type='submit'>Save</button>
      </div>
      </form>
      </div>
    </div>
</div>
      <div className={`absolute ${addcsv?'block':'hidden'} top-72 inset-0 flex items-center justify-center`}>
      <div class="border-2 border-gray-300 bg-white rounded-md p-4 h-[250px] w-[500px]">
        <div className='flex justify-end px-2'>
      <IoCloseCircleSharp size={24} onClick={handlecsvclick} className='cursor-pointer' />
      </div>
      <h2 className='text-center font-semibold'>Download the template</h2>
      <div className='flex flex-col items-center'>
      <MdCloudUpload size={100} color="blue" />
      {/* <button className='py-2 px-4 bg-blue-500 text-white' onClick={handleButtonClick}>Browse files</button> */}
      <input
        type="file"
        ref={fileInputRef}
        // style={{ display: 'none' }}
        onChange={handleFileChange}
        accept=".csv"
        multiple 
      />
       <button onClick={() => fileInputRef.current.click()}>Upload Users</button>
      </div>
    </div>
</div>
    </div>
    </div>
    </Sidebar>
  );
};

export default UsersAndTeam;
