import React from 'react';

const Alert = ({ message }) => {
  if (!message) return null;

  return (
    <div className="bg-red-500 text-white text-center p-4 rounded-md shadow">
      {message}
    </div>
  );
};

export default Alert;
