import React, { useEffect } from "react";
import Sidebar from "../../container/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Onboard = () => {

  // function getCookie(name) {
  //   const value = `; ${document.cookie}`;
  //   const parts = value.split(`; ${name}=`);
  //   if (parts.length === 2) return parts.pop().split(';').shift();
  //   return null;
  // }

  // function deleteCookie(name) {
  //   const isLocalhost = window.location.hostname === "localhost";
  //   const cookieDomain = isLocalhost ? "localhost" : "dashboard.triumphiq.com";
    
  //   document.cookie = `${name}=; path=/; domain=${cookieDomain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=Strict`;
  // }
  
  // const token = getCookie('authToken');

  // if (token) {
  //   localStorage.setItem("authToken", token);
  // } 
 
  // if(localStorage.getItem("authToken")!==null){
  //   deleteCookie('authToken');
  // }
  
  // const navigate = useNavigate();
  // const location = useLocation();

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(window.location.search);
  //   const token = queryParams.get("token");
  
  //   if (token) {
  //     localStorage.setItem("authToken", token);
  //   } 
  // }, [location, navigate]);

  return (
    <Sidebar>
      <div className="bg-gray-50 p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Welcome to TRIUMPH IQ
        </h2>
        <p className="text-gray-700 mb-4 font-semibold">
          Complete your setup with easy steps
        </p>
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-semibold mb-2">Step 1</h3>
            <Link to="/settings">
            <div className="bg-gray-100 p-4 rounded-lg border-2 border-gray-300 cursor-pointer">
              <p>Add users / CSM / Managers for your accounts</p>
            </div>
            </Link>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2 ">Step 2</h3>
            <Link to="/settings">
            <div className="bg-gray-100 p-4 rounded-lg border-2 border-gray-300 cursor-pointer">
              <p>Create and Update the Fields for your Accounts</p>
            </div>
            </Link>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Step 3</h3>
            <div className="bg-gray-100 p-4 rounded-lg border-2 border-gray-300 cursor-pointer">
              <p>Upload the Accounts to see the Magic</p>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default Onboard;
