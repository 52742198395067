import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Verify = () => {
  const [emailId, setEmailId] = useState("");
  const [emailVerificationToken, setEmailVerificationToken] = useState("");
  const [password, setPassword] = useState(null);
  const [confirm, setConfirm] = useState("");
  const [organisationCname, setOrganisationCname] = useState("");

  const navigate = useNavigate();

  const isPasswordMatch = password === confirm;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const emailCode = params.get("email-verification-code");
    const email = params.get("user-email");
    const orgcname = params.get("organisation-cname");

    setEmailVerificationToken(emailCode);
    setEmailId(email);
    setOrganisationCname(orgcname);
  }, []);

  useEffect(() => {
    const verifyMail = async () => {
      if (!emailVerificationToken || !emailId || !organisationCname) {
        console.error("Missing required parameters");
        return;
      }
      try {
        const response = await fetch(
          "https://be.triumphiq.com/auth/verify-mail-details",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              emailId,
              emailVerificationToken,
              organisationCname,
            }),
          }
        );

        if(response.status===400){
        const responseData = await response.text();

        if (responseData === "MAIL_TOKEN_INVALID") {
          alert("Mail Token Invalid! Please refer to the login instructions provided on your email to access your account.");
        } else if (responseData === "USER_ALREADY_VERIFIED") {
          alert("User already verified! Please refer to the login instructions provided on your email to access your account.");
        }
      }

        if (!response.ok) {
          throw new Error("Failed to verify email details");
        }

        const data = await response.json();
        console.log("Verification successful:", data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    verifyMail();
  }, [emailVerificationToken, emailId, organisationCname]);

  //password-submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(
      "https://be.triumphiq.com/auth/submit-password",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailId,
          emailVerificationToken,
          organisationCname,
          password,
        }),
      }
    );
    if (response.status === 400) {
      alert("User already verified!");
    }
    if (response.ok) {
      alert("Success");
      window.location.href = `https://${organisationCname}.dashboard.triumphiq.com/re-login`;
    } else {
      console.error("SignUp failed", response);
    }
  };

  return (
    <div className="w-full h-screen p-20 flex gap-4 bg-gray-50">
      <div className=" h-[600px] w-[50%] mt-7 mx-5">
        <div className="">
          <h2 className="text-[24px] font-bold">Activate your Account</h2>

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <p className="text-sm font-semibold">Email address</p>
              <input
                id="email"
                type="email"
                required
                placeholder="email"
                value={emailId}
                disabled
                onChange={(e) => setEmailId(e.target.value)}
                className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
              />
            </div>
            <div>
              <p className="text-sm font-semibold">Organisation Name</p>
              <input
                id="text"
                type="text"
                required
                placeholder="organisation"
                disabled
                value={organisationCname}
                onChange={(e) => setOrganisationCname(e.target.value)}
                className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
              />
            </div>
            <div>
              <p className="text-sm font-semibold">Password</p>
              <input
                id="password"
                type="password"
                required
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
              />
            </div>
            <div>
              <p className="text-sm font-semibold">Confirm Password</p>
              <input
                id="confirm"
                type="text"
                required
                placeholder="confirm password"
                value={confirm}
                onChange={(e) => setConfirm(e.target.value)}
                className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
              />
            </div>

            <button
              type="submit"
              className={`w-[500px] h-12 rounded-full flex items-center justify-center 
                  bg-blue-700 text-white ${
                    !isPasswordMatch && "opacity-50 cursor-not-allowed"
                  }`}
              disabled={!isPasswordMatch}
            >
              Activate your Account
            </button>
          </form>
        </div>
      </div>
      <div className="h-[550px] my-auto w-[50%] bg-blue-600 rounded-2xl">
        {/* content  */}
        <div className="p-8 px-12 text-white">
          <h2 className="text-[24px] font-bold w-[350px] ">
            The simplest way to manage your Customers
          </h2>
          <p className="text-sm text-gray-200 w-[260px] mt-3">
            Know your customer better. Reduce Churn. Grow your Cusomters
          </p>
          {/* <div className='mt-10'>
     <Carousel/>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Verify;
