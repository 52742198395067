import React, { useEffect, useState } from "react";
import {
  MdOutlineSupervisorAccount,
  MdSupervisorAccount,
} from "react-icons/md";
import { TbBriefcase2Filled } from "react-icons/tb";
import Sidebar from "../container/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Dashboard = () => {

//  const navigate = useNavigate();
// const location = useLocation();

// useEffect(() => {
//   const queryParams = new URLSearchParams(window.location.search);
//   const token = queryParams.get("token");

//   if (token) {
//     localStorage.setItem("authToken", token);
//   } 
// }, [location, navigate]);


  const [data, setData]= useState(null);

  //fetch api
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await fetch("https://be.triumphiq.com/analytics", {
  //         headers: {
  //           Authorization: "Bearer ",
  //         },
  //       });
  //       if (!res.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       const result = await res.json();
  //       setData(result);
  //     } catch (err) {
  //       console.log("Error", err);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <Sidebar>
      <div className="h-screen ">
        {/* button  */}

        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-2 ">
          <div className="h-24 bg-gray-50 rounded-md shadow border border-gray-200 p-2 flex flex-col justify-between">
            <div className="flex gap-2 items-center ">
              <MdSupervisorAccount size={30} color="gray" />{" "}
              <h2 className="text-base xl:text-[18px]">My Accounts</h2>
            </div>
            <div className="flex justify-between px-1">
              <h3 className="text-[20px]">234</h3>
              <p>+5% last month</p>
            </div>
          </div>
          <div className="h-24 bg-gray-50 rounded-md shadow border border-gray-200 p-2 flex flex-col justify-between">
            <div className="flex gap-2 items-center ">
              <TbBriefcase2Filled size={28} color="gray" />{" "}
              <h2 className="text-base xl:text-[18px]">Total ARR</h2>
            </div>
            <div className="flex justify-between px-1">
              <h3 className="text-[20px]">234</h3>
              <p>+5% last month</p>
            </div>
          </div>
          <div className="h-24 bg-gray-50 rounded-md shadow border border-gray-200 p-2 flex flex-col justify-between">
            <div className="flex gap-2 items-center ">
              <MdOutlineSupervisorAccount size={30} color="gray" />{" "}
              <h2 className="text-base xl:text-[18px]">
                New Accounts <span className="text-sm">(last 30 days)</span>
              </h2>
            </div>
            <div className="flex justify-between px-1">
              <h3 className="text-[20px]">234</h3>
              <p>$3000</p>
            </div>
          </div>
          <div className="h-24 bg-gray-50 rounded-md shadow border border-gray-200 p-2 flex flex-col justify-between">
            <div className="flex gap-2 items-center ">
              <TbBriefcase2Filled size={28} color="gray" />{" "}
              <h2 className="text-base xl:text-[18px]">
                My High Risk Accounts
              </h2>
            </div>
            <div className="flex justify-between px-1">
              <h3 className="text-[20px]">234</h3>
              <p>$3000</p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-2 mt-3">
          <div className="h-20 w-full bg-gray-50 rounded-md shadow border border-gray-100 p-1.5 flex flex-col justify-between text-sm">
            <h2>
              Renewal <span className="lg:text-xs">(Next 30 days)</span>
            </h2>
            <div className="flex justify-between items-center ">
              <p>300</p>
              <p>df</p>
            </div>
          </div>
          <div className="h-20 w-full bg-gray-50 rounded-md shadow border border-gray-100 p-1.5 flex flex-col justify-between text-sm">
            <h2>
              Renewal <span className="lg:text-xs">(Next 30 days)</span>
            </h2>
            <div className="flex justify-between items-center ">
              <p>300</p>
              <p>df</p>
            </div>
          </div>
          <div className="h-20 w-full bg-gray-50 rounded-md shadow border border-gray-100 p-1.5 flex flex-col justify-between text-sm">
            <h2>
              Renewal <span className="lg:text-xs">(Next 30 days)</span>
            </h2>
            <div className="flex justify-between items-center ">
              <p>300</p>
              <p>df</p>
            </div>
          </div>
          <div className="h-20 w-full bg-gray-50 rounded-md shadow border border-gray-100 p-1.5 flex flex-col justify-between text-sm">
            <h2>
              Renewal <span className="lg:text-xs">(Next 30 days)</span>
            </h2>
            <div className="flex justify-between items-center ">
              <p>300</p>
              <p>df</p>
            </div>
          </div>
          <div className="h-20 w-full bg-gray-50 rounded-md shadow border border-gray-100 p-1.5 flex flex-col justify-between text-sm">
            <h2>
              Renewal <span className="lg:text-xs">(Next 30 days)</span>
            </h2>
            <div className="flex justify-between items-center ">
              <p>300</p>
              <p>df</p>
            </div>
          </div>
          <div className="h-20 w-full bg-gray-50 rounded-md shadow border border-gray-100 p-1.5 flex flex-col justify-between text-sm">
            <h2>
              Renewal <span className="lg:text-xs">(Next 30 days)</span>
            </h2>
            <div className="flex justify-between items-center ">
              <p>300</p>
              <p>df</p>
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 gap-2 grid-cols-1 mt-4">
          <div className="h-72 rounded-md border border-gray-100 shadow w-full bg-gray-50">
            <h2 className="px-3 py-1">High Risk Accounts</h2>
          </div>
          <div className="h-72 rounded-md border border-gray-100 shadow w-full bg-gray-50"></div>
        </div>
      </div>
    </Sidebar>
  );
};

export default Dashboard;
