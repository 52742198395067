import React, { useEffect, useState } from "react";
import Settingbar from "../../container/Settingbar";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import {
  IoArrowBackCircleOutline,
  IoCloseCircleSharp,
  IoSearch,
} from "react-icons/io5";
import { BiSearchAlt } from "react-icons/bi";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import Sidebar from "../../container/Sidebar";

const ContactInfo = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const contactData = [
    "Account Name",
    "Account Website",
    "First Name",
    "Last Name",
    "Email Id",
    "Phone Number",
  ];

  /* 1. Api calls */
  // get all acc details

  const filteredFields = contactData?.filter((field) =>
    field.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <Sidebar>
      <div className="container mx-auto h-screen  relative bg-white">
        <div className="pt-[24px] px-[24px] mb-2 gap-2  flex items-center">
          <Link to="/settings">
            <IoArrowBackCircleOutline size={24} />
          </Link>
          <h2 className="text-2xl ">Contact Information</h2>
        </div>
        <div className="bg-white  rounded-md shadow-md border-gray-200 border mb-2 mx-6 mt-6">
          <div className="h-14 w-full  flex justify-between items-center px-4 border-b-2 ">
            <h2 className="text-base px-3">Contact Details</h2>

            <div className="flex items-center gap-4 relative">
              <div className="mb-1">
                <BiSearchAlt color="gray" className="absolute top-2 left-1" />

                <input
                  type="text"
                  placeholder="Search by field name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="border border-gray-300 rounded-md py-1 px-6 w-64 text-sm focus:outline-gray-400"
                />
              </div>

              <button
                className="bg-blue-500 h-8 w-32 text-white rounded-md text-sm"
                // onClick={handleOnclick}
              >
                Add Fields
              </button>
            </div>
          </div>
          <div className="p-4">
            <table className="min-w-full bg-white ">
              <thead>
                <tr className="font-semibold">
                  <th className="py-2 px-3 text-left text-sm text-gray-600 ">
                    Field Name
                  </th>
                  <th className="py-2 text-left text-sm text-gray-600 ">
                    Type
                  </th>
                  <th className="py-2 text-left text-sm text-gray-600 ">
                    Action
                  </th>
                  <th className="py-2 text-left text-sm text-gray-600 ">
                    Visibility
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredFields.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className="py-2 px-3 text-sm text-gray-700 ">
                        {data}
                      </td>
                      <td className="pl-4">-</td>
                      <td className="pl-4">-</td>
                      <td className="pl-4">-</td>
                    </tr>
                  );
                })}

                {/* {filteredFields?.slice(0, showAll ? filteredFields.length : 3).map((field, index) => (
                  <tr key={index}>
                    <td className="py-2 px-3 text-sm text-gray-700 ">
                      {field?.fieldName}
                    </td>
                    <td className="py-2 text-sm text-gray-700 capitalize ">
                    {field.fieldType==="ENUM"? <p>Dropdown</p> : field.fieldType.toLowerCase() }
                    </td>
                    <td className="py-2 text-sm text-gray-700 ">
              <button className="text-blue-500 mr-2" onClick={() => handleEditClick(index)}>Edit</button>       
                    {index>2?
                      <button className="text-red-500"   onClick={() => confirmDelete(index)} // Show confirmation modal
                        >Delete</button>
                      :null}
                    </td>
                    <td className="py-2 text-sm text-gray-700">
                    {index < 3 ? (
                      <span className="text-green-500 font-semibold">ON</span>
                    ) : (
                      <div className="relative inline-block w-12 h-6 align-middle select-none transition duration-200 ease-in">
                        <input
                          type="checkbox"
                          name={`toggle-${index}`}
                          id={`toggle-${index}`}
                          checked={field.visibility} // Set the toggle based on visibility
                          onChange={() => handleToggleVisibility(index)} // Toggle on change
                          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition-transform duration-300 ease-in-out"
                          style={{
                            transform: field.visibility
                              ? "translateX(1.5rem)"
                              : "translateX(0)",
                          }}
                        />
                        <label
                          htmlFor={`toggle-${index}`}
                          className={`toggle-label block overflow-hidden h-6 rounded-full ${
                            field.visibility ? "bg-green-500" : "bg-gray-300"
                          } cursor-pointer transition-colors duration-300`}
                        ></label>
                      </div>
                    )}
                  </td>
                  </tr>
                ))} */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default ContactInfo;
