import React, { useEffect, useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import Topbar from "./Topbar";
import Sidebar from "../../container/Sidebar";
import Project from "./Project";
import { useParams } from "react-router-dom";
import Revenue from "./Revenue";
import Health from "./Health";
import Request from "./Request";
import Notes from "./Notes";
import Engagement from "./Engagement";
import { FaPlus } from "react-icons/fa";
import { HashLink as Link } from "react-router-hash-link";
import Overview from "./Overview";

const SingleAccount = () => {
  const category = [
    "Overview",
    "Health",
    "Project",
    "Request",
    "Engagement",
    "Revenue",
    "Notes",
  ];

  const [activeSection, setActiveSection] = useState("overview");

  const { id } = useParams();

  useEffect(() => {
    const handleScroll = () => {
      const sections = category.map((section) =>
        document.getElementById(section)
      );
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveSection(entry.target.id);
            }
          });
        },
        { threshold: 0.6 } // Adjust as needed
      );

      sections.forEach((section) => {
        if (section) observer.observe(section);
      });

      return () => {
        sections.forEach((section) => {
          if (section) observer.unobserve(section);
        });
      };
    };

    handleScroll();
  }, [category]);

  return (
    <Sidebar>
      <div className="h-16 md:h-12 inset-0 ml-auto rounded-b-sm  px-2  flex justify-between w-full  items-center top-10   fixed bg-slate-200  z-20 lg:w-[83.33%]" >
        <div className="gap-x-3  gap-y-2 flex items-center w-full flex-wrap">
          {category.map((data, index) => {
            return (
              <Link
                to={`/accounts/${id}#${data}`}
                key={index}
                className={`capitalize px-2 border border-black text-sm ${
                  activeSection === data ? "bg-black text-white" : "text-black"
                }`}
                smooth
              >
                {data}
              </Link>
            );
          })}
        </div>
        <button className="text-[14px] md:flex items-center bg-blue-500 px-4 rounded-lg gap-x-2 hidden text-white h-7 w-[140px]">
          <FaPlus size={10} /> New Entry
        </button>
      </div>

      <>
      <Overview />
        <Health />
        <Project />
        <Request />
        <Engagement />
        <Revenue />
        <Notes />
      </>
    </Sidebar>
  );
};

export default SingleAccount;
