import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { LiaCommentSolid } from "react-icons/lia";
import { PiLinkSimpleBold } from "react-icons/pi";
import { TbFileDescription } from "react-icons/tb";

const Project = () => {
  const data = [
    {
      id: 1,
      title: "Project 1",
      status: "in-progress",
      startedAt: "2024-08-01",
      dueDate: "2024-08-15",
      outcome: "Pending",
      comments: "",
    },
    {
      id: 2,
      title: "Project 2",
      status: "open",
      startedAt: "2024-07-20",
      dueDate: "2024-08-05",
      outcome: "Success",
      comments: "Delivered on time",
    },
    {
      id: 3,
      title: "Project 3",
      status: "not-started",
      startedAt: "",
      dueDate: "2024-08-20",
      outcome: "Pending",
      comments: "",
    },
    {
      id: 4,
      title: "Project 4",
      status: "in-progress",
      startedAt: "2024-08-05",
      dueDate: "2024-08-18",
      outcome: "Pending",
      comments: "Requires additional resources",
    },
    {
      id: 5,
      title: "Project 5",
      status: "open",
      startedAt: "2024-07-25",
      dueDate: "2024-08-10",
      outcome: "Pending",
      comments: "Awaiting client feedback",
    },
    {
      id: 6,
      title: "Project 6",
      status: "not-started",
      startedAt: "",
      dueDate: "2024-09-01",
      outcome: "Pending",
      comments: "",
    },
    {
      id: 7,
      title: "Project 7",
      status: "in-progress",
      startedAt: "2024-08-03",
      dueDate: "2024-08-22",
      outcome: "Pending",
      comments: "High priority",
    },
    {
      id: 8,
      title: "Project 8",
      status: "open",
      startedAt: "2024-07-30",
      dueDate: "2024-08-17",
      outcome: "Pending",
      comments: "Review in progress",
    },
    {
      id: 9,
      title: "Project 9",
      status: "not-started",
      startedAt: "",
      dueDate: "2024-09-05",
      outcome: "Pending",
      comments: "",
    },
    {
      id: 10,
      title: "Project 10",
      status: "in-progress",
      startedAt: "2024-08-07",
      dueDate: "2024-08-25",
      outcome: "Pending",
      comments: "Requires approval",
    },
  ];

  const [projects] = useState(data);
  const [filter, setFilter] = useState('all');
  const [startRange, setStartRange] = useState("");
  const [endRange, setEndRange] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen]= useState(false)
  const projectsPerPage = 3;


  const filteredProjects = projects.filter(project => {
    const statusMatch = filter === 'all' || project.status === filter;
    const dateMatch =
      (startRange === "" || endRange === "") ||
      (project.startedAt &&
        new Date(project.startedAt) >= new Date(startRange) &&
        new Date(project.startedAt) <= new Date(endRange));

    return statusMatch && dateMatch;
  });


  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

 
  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const Ontoggle =()=>{
    setOpen(!open);
  }
  return (
    <div className="w-full h-full px-3 pt-24 relative mb-10" id="Project">
     
      <div className="h-auto  w-full bg-white rounded-md p-3">
        <div className="flex items-center gap-3 justify-between">
          <div className="flex items-center gap-2">
          <h2 className="mr-8">Project/Goals</h2>
          <input
            type="search"
            name=""
            id=""
            className="bg-gray-100 rounded-md h-8 px-2 border-gray-300 border-2"
            placeholder="search"
          />
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"  onClick={() => setFilter('all')}>All</button>
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"   onClick={() => setFilter('open')}>Open</button>{" "}
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"   onClick={() => setFilter('in-progress')}>In-progress</button>{" "}
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"  onClick={() => setFilter('not-started')}>Not started</button>
          <div className="flex gap-2">
              <input
                type="date"
                className="ml-2 px-2 py-1 border-2 rounded"
                value={startRange}
                onChange={(e) => setStartRange(e.target.value)}
              />
              <span className="self-center">to</span>
              <input
                type="date"
                className="ml-2 px-2 py-1 border-2 rounded"
                value={endRange}
                onChange={(e) => setEndRange(e.target.value)}
              />
            </div>
        </div>
          <button className="h-8 w-32 bg-blue-500 text-sm rounded-md text-white" onClick={Ontoggle}>
            + New Project
          </button>
        </div>
        {/* table contents  */}

        <div className="border border-gray-300 h-[166px] my-3 rounded-md">
        <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b bg-gray-100">{" "}</th>
            <th className="py-2 px-4 border-b bg-gray-100">Title</th>
            <th className="py-2 px-4 border-b bg-gray-100">Status</th>
            <th className="py-2 px-4 border-b bg-gray-100">Started at</th>
            <th className="py-2 px-4 border-b bg-gray-100">Due Date</th>
            <th className="py-2 px-4 border-b bg-gray-100">Outcome</th>
            <th className="py-2 px-4 border-b bg-gray-100">Comments</th>
          </tr>
        </thead>
        <tbody>
          {currentProjects.map((project, index) => (
            <tr key={project.id} >
              <td className="py-2 px-4 border-b flex justify-center">{index + 1}.</td>
              <td className="py-2 px-4 border-b text-center">{project.title}</td>
              <td className="py-2 px-4 border-b text-center">{project.status}</td>
              <td className="py-2 px-4 border-b text-center">{project.startedAt}</td>
              <td className="py-2 px-4 border-b text-center">{project.dueDate}</td>
              <td className="py-2 px-4 border-b text-center">{project.outcome}</td>
              <td className="py-2 px-4 border-b text-center">{project.comments}</td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>
      <div className="mt-4 flex justify-center items-center gap-2">
        <FaChevronLeft 
         className={`cursor-pointer text-gray-600 rounded ${currentPage === 1 && 'opacity-40 cursor-not-allowed'}`} 
         onClick={handlePreviousPage}
         disabled={currentPage === 1}
         size={20} 
       />
        <FaChevronRight 
          
          className={` cursor-pointer text-gray-600 rounded ${currentPage === totalPages && 'opacity-40 cursor-not-allowed'}`} 
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          size={20}
          />
        {/* <span>Page {currentPage} of {totalPages}</span> */}
        
      </div>
      </div>
      <div className="h-auto  w-full bg-white rounded-md p-3 mt-10">
        <div className="flex items-center gap-3 justify-between">
          <div className="flex items-center gap-2">
          <h2 className="mr-8">Tasks</h2>
          <input
            type="search"
            name=""
            id=""
            className="bg-gray-100 rounded-md h-8 px-2 border-gray-300 border-2"
            placeholder="search"
          />
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"  onClick={() => setFilter('all')}>All</button>
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"   onClick={() => setFilter('open')}>Open</button>{" "}
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"   onClick={() => setFilter('in-progress')}>In-progress</button>{" "}
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"  onClick={() => setFilter('not-started')}>Not started</button>
          
        </div>
          <button className="h-8 w-32 bg-blue-500 text-sm rounded-md text-white" onClick={Ontoggle}>
            + New Task
          </button>
        </div>
        {/* table contents  */}

        <div className="border border-gray-300 h-[166px] my-3 rounded-md">
        <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b bg-gray-100">{" "}</th>
            <th className="py-2 px-4 border-b bg-gray-100">Title</th>
            <th className="py-2 px-4 border-b bg-gray-100">Status</th>
            <th className="py-2 px-4 border-b bg-gray-100">Type</th>
            <th className="py-2 px-4 border-b bg-gray-100">Started at</th>
            <th className="py-2 px-4 border-b bg-gray-100">Due Date</th>
            <th className="py-2 px-4 border-b bg-gray-100">Comments</th>
          </tr>
        </thead>
        <tbody>
          {currentProjects.map((project, index) => (
            <tr key={project.id} >
              <td className="py-2 px-4 border-b flex justify-center">{index + 1}.</td>
              <td className="py-2 px-4 border-b text-center">{project.title}</td>
              <td className="py-2 px-4 border-b text-center">{project.status}</td>
              <td className="py-2 px-4 border-b text-center">{project.startedAt}</td>
              <td className="py-2 px-4 border-b text-center">{project.dueDate}</td>
              <td className="py-2 px-4 border-b text-center">{project.outcome}</td>
              <td className="py-2 px-4 border-b text-center">{project.comments}</td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>
      <div className="mt-4 flex justify-center items-center gap-2">
        <FaChevronLeft 
         className={`cursor-pointer text-gray-600 rounded ${currentPage === 1 && 'opacity-40 cursor-not-allowed'}`} 
         onClick={handlePreviousPage}
         disabled={currentPage === 1}
         size={20} 
       />
        <FaChevronRight 
          
          className={` cursor-pointer text-gray-600 rounded ${currentPage === totalPages && 'opacity-40 cursor-not-allowed'}`} 
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          size={20}
          />
        {/* <span>Page {currentPage} of {totalPages}</span> */}
        
      </div>
      </div>
   
      <div className="h-auto  w-full bg-white rounded-md p-3 mt-10">
        <div className="flex items-center gap-3 justify-between">
          <div className="flex items-center gap-2">
          <h2 className="mr-8">Quarterly Business Review's</h2>
          {/* <input
            type="search"
            name=""
            id=""
            className="bg-gray-100 rounded-md h-8 px-2 border-gray-300 border-2"
            placeholder="search"
          />
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"  onClick={() => setFilter('all')}>All</button>
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"   onClick={() => setFilter('open')}>Open</button>{" "}
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"   onClick={() => setFilter('in-progress')}>In-progress</button>{" "}
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"  onClick={() => setFilter('not-started')}>Not started</button>
          <input 
          type="date" 
          className="ml-2 px-2 py-1 border-2 rounded" 
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        /> */}
        </div>
          <button className="h-8 w-32 bg-blue-500 text-sm rounded-md text-white" onClick={Ontoggle}>
            + QBR
          </button>
        </div>
        {/* table contents  */}

        <div className="border border-gray-300 h-[166px] my-3 rounded-md">
        <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b bg-gray-100">{" "}</th>
            <th className="py-2 px-4 border-b bg-gray-100">Title</th>
            <th className="py-2 px-4 border-b bg-gray-100">Status</th>
            <th className="py-2 px-4 border-b bg-gray-100">Date</th>
            <th className="py-2 px-4 border-b bg-gray-100">Engagement</th>
            <th className="py-2 px-4 border-b bg-gray-100">Rating</th>
            {/* <th className="py-2 px-4 border-b bg-gray-100">Comments</th> */}
          </tr>
        </thead>
        <tbody>
          {currentProjects.map((project, index) => (
            <tr key={project.id} >
              <td className="py-2 px-4 border-b flex justify-center">{index + 1}.</td>
              <td className="py-2 px-4 border-b text-center">{project.title}</td>
              <td className="py-2 px-4 border-b text-center">{project.status}</td>
              <td className="py-2 px-4 border-b text-center">{project.startedAt}</td>
              <td className="py-2 px-4 border-b text-center">{project.dueDate}</td>
              <td className="py-2 px-4 border-b text-center">{project.outcome}</td>
              {/* <td className="py-2 px-4 border-b text-center">{project.comments}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
        </div>
      <div className="mt-4 flex justify-center items-center gap-2">
        <FaChevronLeft 
         className={`cursor-pointer text-gray-600 rounded ${currentPage === 1 && 'opacity-40 cursor-not-allowed'}`} 
         onClick={handlePreviousPage}
         disabled={currentPage === 1}
         size={20} 
       />
        <FaChevronRight 
          
          className={` cursor-pointer text-gray-600 rounded ${currentPage === totalPages && 'opacity-40 cursor-not-allowed'}`} 
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          size={20}
          />
        {/* <span>Page {currentPage} of {totalPages}</span> */}
        
      </div>
      </div>
   
   
      <div className={`${open?'absolute':'hidden'} right-0 bottom-0 h-[80%] z-10 w-[70%] xl:w-[60%]  bg-white border-2 shadow-md border-gray-200`}>
     <div className="flex justify-end">
      <IoClose size={22} color="red" className='cursor-pointer  ' onClick={Ontoggle} />
      </div>
      <div className="flex items-center pt-0 pb-4 px-6 border-b border-gray-300">
<input type="text" className="font-bold  " placeholder="Enter Project Title"/>

      </div>

      {/* input fields  */}
      <div className="flex gap-4 flex-wrap items-center pt-0 pb-4 px-6 text-center capitalize text-sm my-5">
        <div className="w-1/6 space-y-2">
        <h3>Status</h3>
        <select name="" id="" className="w-full border-b shadow rounded-sm border-gray-200">
          <option value="open">Open</option>
          <option value="not_started">Not started</option>
          <option value="">In-Progress</option>
          <option value="" disabled selected hidden >dropdown</option>
        </select>
        </div>
        <div className="w-1/6 space-y-2">
          <h3>started at</h3>
          <input type="date" className="w-full border-b shadow rounded-sm border-gray-200" />
        </div>
        <div className="w-1/6 space-y-2">
          <h3>Due date</h3>
          <input type="date" className="w-full border-b shadow rounded-sm border-gray-200" />

        </div>
        <div className="w-1/6 space-y-2">
          <h3>outcome</h3>
          <input type="text" className=" border rounded-sm w-full " />

        </div>
        <div className="w-1/6 space-y-2">
          <h3 className="capitalize">created by</h3>
          <input type="text" className="border rounded-sm w-full" />

        </div>

      </div>
      {/* description  */}
      <div className="w-full h-[200px]  px-3 space-y-2 text-sm">
        <div className="flex items-center gap-1">
        <TbFileDescription size={20} />
         <h4 className="font-semibold">Description</h4>
        </div>
        
        <textarea id="w3review" placeholder="Enter the description" name="w3review" className="w-full h-[150px] rounded-md shadow border border-gray-300 p-1"></textarea>
      </div>
      <div className="px-3 text-sm font-semibold space-y-2">
        <div className="flex items-center gap-1">
        <PiLinkSimpleBold size={20} /> 
        <h4>Links</h4>
        </div>
        <input type="url" name="" id="" placeholder="Enter url" className=" px-2 font-normal border-2 border-gray-300 rounded-md border-opacity-50 h-8 w-[300px]"/>
      </div>
      
      <div className="w-full h-[80px]  px-3 space-y-2 text-sm mt-5">
        <div className="flex items-center gap-1">
        <LiaCommentSolid  size={20} />
         <h4 className="font-semibold">Comments</h4>
        </div>
        
        <textarea id="w3review" placeholder="comments here..." name="w3review" className="w-full h-[80px] rounded-md shadow border border-gray-300 p-1"></textarea>
      </div>
      </div>
    </div>
  );
};

export default Project;
