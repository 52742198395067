import React, { Children, useEffect, useState } from "react";
import { BsFillSunFill, BsMoonStarsFill } from "react-icons/bs";
import {
  FaAddressBook,
  FaBell,
  FaPlus,
  FaRegUser,
  FaShieldAlt,
} from "react-icons/fa";
import Overview from "../components/singlePageAccount/Overview";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  IoCloseSharp,
  IoHelpCircleOutline,
  IoMoon,
  IoSettingsOutline,
} from "react-icons/io5";
import { HiMiniUsers } from "react-icons/hi2";
import { RiUserAddFill } from "react-icons/ri";
import { PiPlugsConnectedFill } from "react-icons/pi";
import { MdContactPage, MdSpaceDashboard } from "react-icons/md";
import { FaCodePullRequest } from "react-icons/fa6";
import { HiDocumentReport } from "react-icons/hi";
import { CiBag1 } from "react-icons/ci";
import Account from "../pages/Account";
import Dashboard from "../pages/Dashboard";
import Project from "../components/singlePageAccount/Project";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

const Sidebar = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [greeting, setGreeting] = useState("");
  const [name, setName] = useState('');
  const navigate = useNavigate()

  const handleOnlclick = () => {
    setOpen(!open);
    console.log(open);
  };

  useEffect(() => {
    // Retrieve JWT token from localStorage
    const jwtToken = localStorage.getItem('jwtToken');

    // If token exists, decode it and set the name
    if (jwtToken) {
      const decodedName = decodeJWT(jwtToken);
      setName(decodedName);
    }
  }, []);

  useEffect(() => {
    const calculateGreeting = () => {
      const now = new Date();

      const istOffset = 5.5 * 60 * 60 * 1000;
      const istTime = new Date(now.getTime() + istOffset);

      const hours = istTime.getUTCHours();

      if (hours < 12) {
        setGreeting("Good Morning");
      } else if (hours >= 12 && hours < 17) {
        setGreeting("Good Afternoon");
      } else {
        setGreeting("Good Evening");
      }
    };

    calculateGreeting();

    const interval = setInterval(calculateGreeting, 60000);
    return () => clearInterval(interval);
  }, []);

  const handlelogout = () =>{
    if(localStorage.getItem('authToken')){
         localStorage.removeItem('authToken');
         window.location.href= "/login"
    }
  }

  return (
    <div className="w-full h-full relative">
      {/* height 64px  */}
        <div className="w-full h-14 fixed top-0 bg-white z-[100] border-b-[8px] border-grey flex items-center justify-between px-4">   
          {/* <div className="flex items-center justify-between pt-2 mx-3"> */}
            <div className="flex gap-2 items-center">
              <FaAddressBook size={16} color="scarlet" />
              <h2 className="text-[14px]">{window.location.hostname.split('.')[0]}</h2>
            </div>
            <div className="flex  space-x-2 items-center text-sm">
              {greeting === "Good Evening" ? (
                <BsMoonStarsFill size={16} color="gray" />
              ) : (
                <BsFillSunFill size={16} color="orange" />
              )}
              <p>{greeting}</p>
              <p>
              <strong>{name ? name : 'Unknown'}</strong>
              </p>
            </div>
          {/* </div> */}
        </div>
        
        <div className="h-screen w-full bg-grey">  

        <div className="  hidden lg:block w-full ">
          <div className="fixed top-0 left-0 h-screen  z-50 w-[266px] bg-white border-r-[10px] border-grey">
            <div className="mt-20 text-sm">
              <NavLink
                to="/analytics"
                className={({ isActive }) =>
                  `flex items-center gap-2 h-8  w-64 mb-4 text-[16px] px-6 ${
                    isActive ? "bg-gray-200  " : "hover:bg-gray-100"
                  }`
                }
              >
                <MdSpaceDashboard size={17} />
                DashBoard
              </NavLink>
              {/* <div className="h-[0.5px] xl:w-full w-[150px]  bg-gray-400 mt-4"></div> */}
              <h4 className="text-[#9095A1FF] mt-5 text-[18px] px-6">Account Management</h4>
              <div className="mt-5">
                <ul className="space-y-[14px] xl:w-[170px]  text-base ">
                  <NavLink
                    to="/accounts"
                    className={({ isActive }) =>
                      `flex items-center gap-2 h-8 w-64 px-6 ${
                        isActive ? "bg-gray-200 " : "hover:bg-gray-100"
                      }`
                    }
                  >
                    <HiMiniUsers size={15} />
                    Accounts
                  </NavLink>

                  <NavLink
                    to="/integrations"
                    className={({ isActive }) =>
                      `flex items-center gap-2 h-8 w-64 px-6 ${
                        isActive ? "bg-gray-200 " : "hover:bg-gray-100"
                      }`
                    }
                  >
                    <PiPlugsConnectedFill size={15} />
                    Integrations
                  </NavLink>
                  <NavLink
                    to="/contacts"
                    className={({ isActive }) =>
                      `flex items-center gap-2 h-8 w-64 px-6 ${
                        isActive ? "bg-gray-200 " : "hover:bg-gray-100"
                      }`
                    }
                  >
                    <MdContactPage size={15} />
                    Contacts
                  </NavLink>
                  <NavLink
                    to="/settings"
                    className={({ isActive }) =>
                      `flex items-center gap-2 h-8 w-64 px-6 ${
                        isActive ? "bg-gray-200 " : "hover:bg-gray-100"
                      }`
                    }
                  >
                    <IoSettingsOutline size={15} />
                    Settings
                  </NavLink>
                </ul>
                <ul>
                  <button className="fixed bottom-4 left-5 px-6 w-36 rounded-md py-1 text-white text-[14px] font-semibold bg-blue-600" onClick={handlelogout}>Log Out</button>
                </ul>
              </div>

              {/* <div className="h-[0.5px] bg-gray-400 xl:w-full w-[150px]  mt-6 mb-3"></div> */}
              {/* <h3 className="text-[#9095A1FF] mt-5">Support</h3> */}
              <div className="mt-3">
                <ul className="space-y-3 xl:w-[170px] px-2"></ul>
              </div>
            </div>
          </div>
        </div>
        <div className=" ml-64">

          <div className="mt-14 md:mt-12 ml-[10px] ">{children}</div>
        </div>
        <div className="md:hidden  fixed h-10 w-10 bottom-3 rounded-full bg-blue-500 left-3 justify-center flex items-center">
          <FaPlus size={20} color="white" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;


const decodeJWT = (jwtToken) => {
  // Split the JWT token into header, payload, and signature
  const [header, payload, signature] = jwtToken.split('.');

  // Decode the payload (base64url encoded)
  const decodedPayload = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));

  // Parse the decoded JSON
  const payloadData = JSON.parse(decodedPayload);

  // Return the name from the payload
  return payloadData.name;
};