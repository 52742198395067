import React from 'react'
import { AiOutlineTeam } from 'react-icons/ai'
import { BsDatabaseDown, BsFillFileTextFill, BsPeople } from 'react-icons/bs'
import { FaDatabase, FaFileContract, FaUsers } from 'react-icons/fa'
import { FiSettings } from 'react-icons/fi'
import { HiUsers } from 'react-icons/hi2'
import { MdAccountBalance, MdHealthAndSafety, MdOutlineDashboardCustomize } from 'react-icons/md'
import { RiUserSettingsLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import Settingbar from '../container/Settingbar'
import Sidebar from '../container/Sidebar'

const Settings = () => {

  return (
    // <Settingbar>
    <Sidebar>
<div className=" relative h-screen bg-white py-6">
      <div className="space-y">
       
        <div>
          <div className='w-full bg-gray-100 py-[6px] flex items-center px-6 '>
          <h2 className="text-[20px] ">Account Management</h2>
          </div>
          <div className="grid grid-cols-3 gap-y-[24px] px-4 py-8">
            
            <Link to="/settings/accountInfo">
          <div className="flex items-start space-x-3 min-h-[72px] rounded-md hover:bg-gray-100">
              <div className='flex gap-x-2  py-1 px-2 '>
              <RiUserSettingsLine className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Account Information </h3>
                <p className="text-sm text-gray-500">Customize your account fields. Edit or update the predefined fields</p>
                </div>
              </div>
            </div>
            </Link>
            <Link to="/settings/AccountDataUpload">
            <div className="flex items-start space-x-3 hover:bg-gray-100  rounded-md min-h-[72px]">
              <div className='flex gap-x-2  py-1 px-2 '>
              <RiUserSettingsLine className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Account Data Upload</h3>
                <p className="text-sm text-gray-500">Upload your Accounts, Details and assign the Team</p>
                </div>
              </div>
            </div>
            </Link>
            <Link to="/settings/contactInfo">

            <div className="flex items-start space-x-3 hover:bg-gray-100  rounded-md min-h-[72px]">
              <div className='flex gap-x-2  py-1 px-2 rounded-md'>
              <RiUserSettingsLine className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Contact Information</h3>
                <p className="text-sm text-gray-500">Customize your Contact fields. Edit or update the predefined fields</p>
                </div>
              </div>
            </div>
            </Link>

            <Link to="/settings/contactUpload">
            <div className="flex items-start space-x-3 hover:bg-gray-100  rounded-md min-h-[72px]">
              <div className='flex gap-x-2 py-1 px-2'>
              <RiUserSettingsLine className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Contact Upload</h3>
                <p className="text-sm text-gray-500">Upload list of contacts</p>
                </div>
              </div>
            </div>
            </Link>
          </div>
        </div>

     
        <div>
        <div className='w-full bg-gray-100 py-[6px] flex items-center px-6 '>
          <h2 className="text-[20px] ">Health Management</h2>
          </div>
          <div className="grid grid-cols-3 gap-y-[24px] px-4 py-8 ">
            <div className="flex items-start space-x-3 rounded-md hover:bg-gray-100 min-h-[72px]">
              <Link to="/settings/accountDetails">
              <div className='flex gap-x-2  py-1 px-2 '>
              <BsFillFileTextFill className="text-2xl text-blue-500"  size={24}/>
                <div>
                <h3 className="font-medium">Engagement Health </h3>
                <p className="text-sm text-gray-500">Customize your account fields. Edit or update the predefined fields</p>
                </div>
              </div>
              </Link>
            </div>
           
          </div>
        </div>

        {/* Data Import Section */}
        <div>
        <div className='w-full bg-gray-100 py-[6px] flex items-center px-6 '>
          <h2 className="text-xl ">Users and Permission</h2>
          </div>
          <div className="grid grid-cols-3 gap-[24px] py-8 px-4">
            <div className="flex items-start space-x-[24px] ">
            <Link to="/settings/users" className="flex items-start space-x-3 rounded-md hover:bg-gray-100 min-h-[72px]">
              <div className='flex gap-x-2 py-1 px-2 rounded-md '>
              <FaUsers className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Users</h3>
                <p className="text-sm text-gray-500">Create and Manage Users. Define Roles at User Level</p>
                </div>
              </div>
            </Link>
            </div>
            <div className="flex items-start space-x-3 rounded-md hover:bg-gray-100 min-h-[72px]">
              <div className='flex gap-x-2  py-1 px-2 '>
              <RiUserSettingsLine className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Roles and Permissions</h3>
                <p className="text-sm text-gray-500">Provide and restrict fine-grained levels of access and privileges for users</p>
                </div>
              </div>
            </div>
          </div>
        </div>

       
      </div>
    
    </div>



    </Sidebar>
  )
}

export default Settings




