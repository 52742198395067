import React, { useEffect, useState } from 'react'
// import { FaUpload } from 'react-icons/fa'; 
import Settingbar from '../../container/Settingbar'
import { MdArrowBackIosNew } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Sidebar from '../../container/Sidebar'
import { IoArrowBackCircleOutline } from 'react-icons/io5'

const UploadAccData = () => {


  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); 
  };

 const [currentDateTime, setCurrentDateTime] = useState('');

    const getCurrentDateTimeIST = () => {
  
      const currentDate = new Date();

      const utcTime = currentDate.getTime() + currentDate.getTimezoneOffset() * 60000;

      const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
      const istTime = new Date(utcTime + istOffset);

      // Format the date and time with AM/PM
      const options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      };

      const formattedDateTime = istTime.toLocaleString('en-IN', options);
      return formattedDateTime;
    };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://be.triumphiq.com/data-upload/accounts",
        {
          headers: {
            "Accept": "text/csv",
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to download CSV');
      }

      // Get the blob (binary data) from the response
      const blob = await response.blob();

      // Create a link element, use it to download the CSV file
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'accounts_data.csv'; 
      document.body.appendChild(a); 
      a.click(); 
      document.body.removeChild(a); 
    } catch (error) {
      console.error("Error downloading the file:", error);
      alert("Failed to download the file.");
    }
  };


  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    const formData = new FormData();
    formData.append('file', file); // Append the selected file

    try {
      const response = await fetch('https://be.triumphiq.com/data-upload/accounts', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: formData,
      });

      if (response.ok) {
        
        const result = await response.json();
        console.log('File uploaded successfully:', result);
    setCurrentDateTime(getCurrentDateTimeIST());
      } else {
        console.error('Failed to upload file:', response.statusText);
      }
    } catch (error) {
      console.error('Error during the upload process:', error);
    }
  };

  return (
    <Sidebar>
        <div className="container mx-auto h-screen bg-white relative">
        <div className=' pt-[24px] px-[24px] mb-2 border-b-2 border-gray-300  h-24'>
        <div className="flex gap-2 items-center mb-1">
          <Link to="/settings">
          <IoArrowBackCircleOutline size={24} />
          </Link>
          <h2 className=" text-2xl ">Data Upload</h2>
        </div>
          <p className='text-sm ml-8'>Download a sample CSV file tailored to your setup. Add your data and upload it with ease.</p>
          </div>
        <div className="w-full  p-4 bg-white rounded-lg ">
        {/* Last Import Date */}
        <div className="mb-6  p-2 ">
           <p className=" block ml-8 text-sm font-medium">Last import made on :{currentDateTime}</p>
        </div>

        {/* Download Sample CSV */}
        <div className="mb-6 w-[36%] mx-auto border-2 rounded-md border-gray-400 p-5 py-8">
          <h3 className='text-sm text-center'> Download Sample CSV File</h3>
        <div className='flex justify-center mt-5'>
    <button className='text-blue-400 text-sm' onClick={handleOnSubmit}>
    Download File
    </button>
        </div>
        </div>

        {/* Add Account Data */}
        <div className="w-[50%] mx-auto border-2 border-gray-400 rounded-lg p-6 ">
          <div className="  text-sm text-center  mb-2">
            Upload your Accounts Data
          </div>
          <div className="mx-14 text-center  pt-4 flex flex-col items-center ">
            <form onSubmit={handleUpload} className=''>
              <div className='border-dashed border-2 py-4 px-6'>
            <label className="block text-gray-500 mb-2">
              Drag & Drop or Choose file to upload
            </label>
          <div className="text-sm text-gray-500 pb-2">CSV or TXT</div>
            <input
              type="file"
              onChange={handleFileChange} 
              className="block w-full text-sm pl-24 text-gray-900 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
            />

        </div>
          <div className='flex justify-center pt-4 pb-2'>
        <button type="submit" className='bg-blue-500 text-white px-6 py-1 rounded-md mt-3 '>Upload</button>
        </div>
      </form>
          </div>
        </div>
      </div>
        </div>
    </Sidebar>
  )
}

export default UploadAccData
