import React from 'react'
import Sidebar from '../container/Sidebar'

const Request = () => {
  return (
    <Sidebar>
    <div>
      vbkzdkc
    </div>
  </Sidebar>
  )
}

export default Request
