import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { LiaCommentSolid } from "react-icons/lia";
import { PiLinkSimpleBold } from "react-icons/pi";
import { TbFileDescription } from "react-icons/tb";

const Revenue = () => {
  const data = [
    {
      id: 1,
      title: "Project 1",
      status: "in-progress",
      startedAt: "2024-08-01",
      dueDate: "2024-08-15",
      outcome: "Pending",
      comments: "",
    },
    {
      id: 2,
      title: "Project 2",
      status: "open",
      startedAt: "2024-07-20",
      dueDate: "2024-08-05",
      outcome: "Success",
      comments: "Delivered on time",
    },
    {
      id: 3,
      title: "Project 3",
      status: "not-started",
      startedAt: "",
      dueDate: "2024-08-20",
      outcome: "Pending",
      comments: "",
    },

  ];
  const [open, setOpen]= useState(false)
  const Ontoggle =()=>{
    setOpen(!open);
  }

  return (
    <div className="w-full h-[900px] px-3 pt-24 relative mb-10" id="Revenue">
      <div className="h-[470px] w-full bg-white shadow border border-gray-200 ">
        <h2 className="h-14 flex items-center px-6">Contract Details</h2>
        <div className="border-2 border-gray-300 h-[200px] mx-2 rounded-md"></div>
        <div className="border-2 border-gray-300 h-[150px] mx-2 rounded-md mt-5"></div>
      <div className="mt-4 flex justify-center items-center gap-2">
        <FaChevronLeft size={20} />
        <FaChevronRight size={20} />
      </div>
      </div>
      <div className="h-auto  w-full bg-white rounded-md p-3 mt-10 border shadow">
        <div className="flex items-center gap-3 justify-between">
          <div className="flex items-center gap-2">
          <h2 className="mr-8">Deals</h2>
          <input
            type="search"
            name=""
            id=""
            className="bg-gray-100 rounded-md h-8 px-2 border-gray-300 border-2"
            placeholder="search"
          />
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300" >Status</button>
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"   >Type</button>{" "}
          <button className="h-6 w-24 text-sm  border-2 border-opacity-80 border-gray-300"  >Created At</button>{" "}
          <button className="h-6 w-32 text-sm  border-2 border-opacity-80 border-gray-300"  >Expected Date</button>
          
        </div>
          <button className="h-8 w-32 bg-blue-500 text-sm rounded-md text-white" onClick={Ontoggle}>
            + New Project
          </button>
        </div>
        {/* table contents  */}

        <div className="border border-gray-300 h-[166px] my-3 rounded-md">
        <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b bg-gray-100">{" "}</th>
            <th className="py-2 px-4 border-b bg-gray-100">Deal Name</th>
            <th className="py-2 px-4 border-b bg-gray-100">MRR</th>
            <th className="py-2 px-4 border-b bg-gray-100">Type</th>
            <th className="py-2 px-4 border-b bg-gray-100">Started at</th>
            <th className="py-2 px-4 border-b bg-gray-100">Due Date</th>
            <th className="py-2 px-4 border-b bg-gray-100">Comments</th>
          </tr>
        </thead>
        <tbody>
          {data.map((project, index) => (
            <tr key={project.id} >
              <td className="py-2 px-4 border-b flex justify-center">{index + 1}.</td>
              <td className="py-2 px-4 border-b text-center">{project.title}</td>
              <td className="py-2 px-4 border-b text-center">{project.status}</td>
              <td className="py-2 px-4 border-b text-center">{project.startedAt}</td>
              <td className="py-2 px-4 border-b text-center">{project.dueDate}</td>
              <td className="py-2 px-4 border-b text-center">{project.outcome}</td>
              <td className="py-2 px-4 border-b text-center">{project.comments}</td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>
      <div className="mt-4 flex justify-center items-center gap-2">
        <FaChevronLeft 
      
         size={20} 
       />
        <FaChevronRight 
          
         
          size={20}
          />
        {/* <span>Page {currentPage} of {totalPages}</span> */}
        
      </div>
      </div>
      <div className={`${open?'absolute':'hidden'} right-0 bottom-0 h-[70%] z-10 w-[70%] xl:w-[60%]  bg-white border-2 shadow-md border-gray-200`}>
     <div className="flex justify-end">
      <IoClose size={22} color="red" className='cursor-pointer  ' onClick={Ontoggle} />
      </div>
      <div className="flex items-center pt-0 pb-4 px-6 border-b border-gray-300">
<input type="text" className="font-bold  " placeholder="Enter Deal Name"/>

      </div>

      {/* input fields  */}
      <div className="flex gap-4 flex-wrap items-center pt-0 pb-4 px-6 text-center capitalize text-sm my-5">
        <div className="w-1/6 space-y-2">
        <h3>Status</h3>
        <select name="" id="" className="w-full border-b shadow rounded-sm border-gray-200">
          <option value="open">Open</option>
          <option value="not_started">Not started</option>
          <option value="">In-Progress</option>
          <option value="" disabled selected hidden >dropdown</option>
        </select>
        </div>
        <div className="w-1/6 space-y-2">
          <h3>started at</h3>
          <input type="date" className="w-full border-b shadow rounded-sm border-gray-200" />
        </div>
        <div className="w-1/6 space-y-2">
          <h3>Due date</h3>
          <input type="date" className="w-full border-b shadow rounded-sm border-gray-200" />

        </div>
        <div className="w-1/6 space-y-2">
          <h3>outcome</h3>
          <input type="text" className=" border rounded-sm w-full " />

        </div>
        <div className="w-1/6 space-y-2">
          <h3 className="capitalize">created by</h3>
          <input type="text" className="border rounded-sm w-full" />

        </div>

      </div>
      {/* description  */}
      <div className="w-full h-[200px]  px-3 space-y-2 text-sm">
        <div className="flex items-center gap-1">
        <TbFileDescription size={20} />
         <h4 className="font-semibold">Description</h4>
        </div>
        
        <textarea id="w3review" placeholder="Enter the description" name="w3review" className="w-full h-[150px] rounded-md shadow border border-gray-300 p-1"></textarea>
      </div>
      <div className="px-3 text-sm font-semibold space-y-2">
        <div className="flex items-center gap-1">
        <PiLinkSimpleBold size={20} /> 
        <h4>Links</h4>
        </div>
        <input type="url" name="" id="" placeholder="Enter url" className=" px-2 font-normal border-2 border-gray-300 rounded-md border-opacity-50 h-8 w-[300px]"/>
      </div>
      
      <div className="w-full h-[80px]  px-3 space-y-2 text-sm mt-5">
        <div className="flex items-center gap-1">
        <LiaCommentSolid  size={20} />
         <h4 className="font-semibold">Comments</h4>
        </div>
        
        <textarea id="w3review" placeholder="comments here..." name="w3review" className="w-full h-[80px] rounded-md shadow border border-gray-300 p-1"></textarea>
      </div>
      </div>
    </div>
  );
};

export default Revenue;
