import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";

const Login = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [organisationCname, setOrganisationCname] = useState(null);
  const [visible, setVisible] = useState(false);

  const navigate= useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch("https://be.triumphiq.com/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, organisationCname }),
    });

    if (response.status === 400) {
      const responseData = await response.text();
      if (responseData === "USER_NOT_FOUND") {
        alert("User not found!");
      }
    }
    const data = await response.json();
    if (response.ok) {
      console.log("Login successful", data);

      const isLocalhost = window.location.hostname === "localhost";
        const cookieDomain = isLocalhost ? "localhost" : "dashboard.triumphiq.com";
        document.cookie = `authToken=${data.token}; path=/; domain=${cookieDomain}; secure; samesite=Strict`;
      // window.location.href = `${data.organisationUrl}/dashboard?token=${data.token}`;
      window.location.href= `${data.organisationUrl}/analytics`;
        //  window.location.href= `/analytics`;

    } else {
      console.error("Login failed", data);
    }
  };

  // useEffect(() => {
    
  //   const hostname = window.location.hostname;
  
  //   const parts = hostname.split(".");
  
   
  //   if (parts.length > 2 && parts[1] === "dashboard" && parts[2] === "triumphiq") {
  //     setOrganisationCname(parts[0]);
  //     setVisible(true);
  //   }
  // }, []); 
  
  // useEffect(() => {
  //   if (organisationCname === null && visible === false) {
  //     navigate('/signup');
  //   }
  // }, [organisationCname, visible, navigate]); 

  useEffect(() => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');

    if (parts.length > 2 && parts[1] === 'dashboard' && parts[2] === 'triumphiq') {
      const cname = parts[0];
      setOrganisationCname(cname);
      setVisible(true);
      localStorage.setItem('organisationCname', cname); // Persist cname
    } else {
      navigate('/signup');
    }
  }, [navigate]);
  

  return (
    <div className="w-full h-screen p-20 flex gap-4 bg-gray-50">
      <div className=" h-[600px] w-[50%] mt-7 mx-5">
        <div className="">
          <h2 className="text-[24px] font-bold py-3">Login to your Account</h2>

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <p className="text-sm font-semibold">Email address</p>
              <input
                id="email"
                type="email"
                required
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
              />
            </div>
            <div>
              <p className="text-sm font-semibold">Password</p>
              <input
                id="password"
                type="password"
                required
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
              />
            </div>
            {!visible ? (
              <div>
                <p className="text-sm font-semibold">Organisation Name</p>
                <input
                  id="orgcname"
                  type="text"
                  required
                  placeholder="organisation name"
                  value={organisationCname}
                  onChange={(e) => setOrganisationCname(e.target.value)}
                  className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
                />
              </div>
            ) : null}

            <button
              type="submit"
              className={`w-[500px] h-12 rounded-full flex items-center justify-center 
                  bg-blue-700 text-white `}
            >
              Login
            </button>
          </form>
          <p className="text-sm py-2">Forgot Password? <Link to="/forgotpassword" className="underline text-blue-500">click here</Link></p>
        </div>
      </div>
      <div className="h-[550px] my-auto w-[50%] bg-blue-600 rounded-2xl">
        {/* content  */}
        <div className="p-8 px-12 text-white">
          <h2 className="text-[24px] font-bold w-[350px] ">
            The simplest way to manage your Customers
          </h2>
          <p className="text-sm text-gray-200 w-[260px] mt-3">
            Know your customer better. Reduce Churn. Grow your Cusomters
          </p>
          {/* <div className='mt-10'>
     <Carousel/>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
