import React from 'react'

const Health = () => {
  return (
    <div className='h-screen w-full bg-blue-400' id='Health'>
      
    </div>
  )
}

export default Health 
