import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IoAddOutline } from "react-icons/io5";
import { BiEdit, BiSave } from "react-icons/bi";

const Overview = () => {

  const { id } = useParams();

  const [data, setData] = useState('');
  const [editIndex, setEditIndex] = useState({ section: '', index: null });
  const [editValue, setEditValue] = useState('');
  const token = localStorage.getItem("authToken");

  //get all accounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://be.triumphiq.com/accounts?website=${id}`,
          {
            headers: {
              Authorization:
                `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        result.forEach((entry) => {
          entry.companyInfo = JSON.parse(entry.companyInfo);
          entry.accountInfo = JSON.parse(entry.accountInfo);
          entry.healthAndEngagementInfo = JSON.parse(
            entry.healthAndEngagementInfo
          );
          entry.contractAndProductInfo = JSON.parse(
            entry.contractAndProductInfo
          );
        });

        console.log(result); 
        setData(result);
      
      } catch (err) {
        console.log("error", err);
      }
    };

    fetchData();
  }, [id]);


  const handleEditData = async (updatedData) => {
    try {
      const response = await fetch(
        `https://be.triumphiq.com/accounts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              `Bearer ${token}`,
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      console.log(result)
      result.forEach((entry) => {
        entry.companyInfo = JSON.parse(entry.companyInfo);
        entry.accountInfo = JSON.parse(entry.accountInfo);
        entry.healthAndEngagementInfo = JSON.parse(
          entry.healthAndEngagementInfo
        );
        entry.contractAndProductInfo = JSON.parse(
          entry.contractAndProductInfo
        );
      });

      console.log(result);
      setData(result);
      console.log(result[0]?.accountInfo[0]?.fieldName)
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleEdit = (section, index, fieldValue) => {
    setEditIndex({ section, index });
    setEditValue(fieldValue);
  };

  const handleSave = (section, index) => {
    const updatedData = [...data];
    updatedData[0][section][index].fieldValue = editValue; // Update the relevant field
    const payload = {
      website: updatedData[0].website,
      accountInfo: updatedData[0].accountInfo,
      companyInfo: updatedData[0].companyInfo,
      healthAndEngagementInfo: updatedData[0].healthAndEngagementInfo,
      contractAndProductInfo: updatedData[0].contractAndProductInfo,
    };
    handleEditData(payload);
    setData(updatedData);
    setEditIndex({ section: '', index: null }); // Reset the edit state
  };

  return (
    <>
      <div className="mt-24" id="Overview">
      <div className="m-4  gap-6  bg-white h-full rounded-md shadow-sm border  border-gray-200 ">
            <h2 className="px-2 py-1  border-b-2 md:text-base text-sm border-gray-300">
              Account Team
            </h2>
            <div className="my-2 flex justify-around">
            {data[0]?.accountInfo?.map((field, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="font-bold text-sm">{field?.fieldName}</div>
              <div className="relative text-sm flex items-center group">
                {editIndex.section === 'accountInfo' && editIndex.index === index ? (
                  <>
                     <input
                   value={editValue}
                   onChange={(e) => setEditValue(e.target.value)}
                   className="text-sm p-1 border border-gray-300 rounded"
                 />
                    <BiSave
                      onClick={() => handleSave('accountInfo', index)}
                      className="ml-2 cursor-pointer text-green-600"
                      size={16}
                    />
                  </>
                ) : (
                  <>
                    <div>{field?.fieldValue || 'NA'}</div>
                    <BiEdit
                      onClick={() => handleEdit('accountInfo', index, field?.fieldValue)}
                      className="ml-2 cursor-pointer text-blue-600 hover:text-blue-800 opacity-0 group-hover:opacity-100 transition-opacity"
                      size={16}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
            </div>
          
           
            </div>
            <div className="m-4 gap-6 bg-white h-full rounded-md shadow-sm border border-gray-200">
        <h2 className="px-2 py-1 border-b-2 md:text-base text-sm border-gray-300">
          Company Details
        </h2>
        <div className="my-2 flex justify-around">
          {data[0]?.companyInfo?.map((field, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="font-bold text-sm">{field?.fieldName}</div>
              <div className="relative text-sm flex items-center group">
                {editIndex.section === 'companyInfo' && editIndex.index === index ? (
                  <>
                    <input
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      className="text-sm p-1 border border-gray-300 rounded"
                    />
                    <BiSave
                      onClick={() => handleSave('companyInfo', index)}
                      className="ml-2 cursor-pointer text-green-600"
                      size={16}
                    />
                  </>
                ) : (
                  <>
                    <div>{field?.fieldValue || 'NA'}</div>
                    <BiEdit
                      onClick={() => handleEdit('companyInfo', index, field?.fieldValue)}
                      className="ml-2 cursor-pointer text-blue-600 hover:text-blue-800 opacity-0 group-hover:opacity-100 transition-opacity"
                      size={16}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
  <div className="m-4  gap-6 space-y-4 ">
          {/* health  */}
          {/* Health and Management Overview */}
      <div className=" gap-6 bg-white h-full rounded-md shadow-sm border border-gray-200">
        <h2 className="px-2 py-1 border-b-2 md:text-base text-sm border-gray-300">
          Health and Management Overview
        </h2>
        <div className="my-2 flex justify-around">
          {data[0]?.healthAndEngagementInfo?.map((field, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="font-bold text-sm">{field?.fieldName}</div>
              <div className="relative text-sm flex items-center group">
                {editIndex.section === 'healthAndEngagementInfo' && editIndex.index === index ? (
                  <>
                  {field.fieldType!== "ENUM"?
                 
                 <input
                   value={editValue}
                   onChange={(e) => setEditValue(e.target.value)}
                   className="text-sm p-1 border border-gray-300 rounded"
                 />
                 :
                <select  id="fieldValue" className='w-28 border-2 border-gray-300 rounded-md' value={editValue}   onChange={(e) => setEditValue(e.target.value)}>
                 <option value={field.fieldValue}>{field.fieldValue}</option>
                </select>
               }
                    <BiSave
                      onClick={() => handleSave('healthAndEngagementInfo', index)}
                      className="ml-2 cursor-pointer text-green-600"
                      size={16}
                    />
                  </>
                ) : (
                  <>
                    <div>{field?.fieldValue || 'NA'}</div>
                    <BiEdit
                      onClick={() => handleEdit('healthAndEngagementInfo', index, field?.fieldValue)}
                      className="ml-2 cursor-pointer text-blue-600 hover:text-blue-800 opacity-0 group-hover:opacity-100 transition-opacity"
                      size={16}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
          {/* product  */}
          <div className=" gap-6 bg-white h-full rounded-md shadow-sm border border-gray-200">
        <h2 className="px-2 py-1 border-b-2 md:text-base text-sm border-gray-300">
          Contract and Product Details
        </h2>
        <div className="my-2 flex justify-around">
          {data[0]?.contractAndProductInfo?.map((field, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="font-bold text-sm">{field?.fieldName}</div>
              <div className="relative text-sm flex items-center group">
                {editIndex.section === 'contractAndProductInfo' && editIndex.index === index ? (
                  <>
                    <input
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      className="text-sm p-1 border border-gray-300 rounded"
                    />
                    <BiSave
                      onClick={() => handleSave('contractAndProductInfo', index)}
                      className="ml-2 cursor-pointer text-green-600"
                      size={16}
                    />
                  </>
                ) : (
                  <>
                    <div>{field?.fieldValue || 'NA'}</div>
                    <BiEdit
                      onClick={() => handleEdit('contractAndProductInfo', index, field?.fieldValue)}
                      className="ml-2 cursor-pointer text-blue-600 hover:text-blue-800 opacity-0 group-hover:opacity-100 transition-opacity"
                      size={16}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    
        </div>

       
      </div>
    </>
  );
};

export default Overview;
