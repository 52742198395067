import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail]= useState('');
  const [organisationCname, setOrganisationCname]= useState('');

  const navigate = useNavigate();

  const handleOnSubmit = async()=>{
    try {
      const response = await fetch("https://be.triumphiq.com/auth/forgot-password", {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json' 
        },
        body: JSON.stringify({emailId:email, organisationCname}) 
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.text(); 
      alert('Success:', responseData);
      navigate("/login");
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error.message);
    }
  }
  

  return (
    <div className="w-full h-screen p-20 flex gap-4 bg-gray-50">
    <div className=" h-[600px] w-[50%] mt-7 mx-5">
      <div className="">
        <h2 className="text-[24px] font-bold py-3">Forgot your Password</h2>
       
        <form className="space-y-6" onSubmit={handleOnSubmit}>
          <div>
            <p className="text-sm font-semibold">Email address</p>
            <input
              id="email"
              type="email"
              required
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
            />
          </div>
          <div>
            <p className="text-sm font-semibold">Organisation</p>
            <input
              id="password"
              type="password"
              required
              placeholder="organisation"
              value={organisationCname}
              onChange={(e) => setOrganisationCname(e.target.value)}
              className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
            />
          </div>

           <button
      type="submit"
      className={`w-[500px] h-12 rounded-full flex items-center justify-center 
                bg-blue-700 text-white `}
    >
 Create New Password
    </button>
        </form>
      </div>
    </div>
    <div className="h-[550px] my-auto w-[50%] bg-blue-600 rounded-2xl">
      {/* content  */}
      <div className="p-8 px-12 text-white">
        <h2 className="text-[24px] font-bold w-[350px] ">
          The simplest way to manage your Customers
        </h2>
        <p className="text-sm text-gray-200 w-[260px] mt-3">
          Know your customer better. Reduce Churn. Grow your Cusomters
        </p>
        {/* <div className='mt-10'>
   <Carousel/>
      </div> */}
      </div>
    </div>
  </div>
  )
}

export default ForgotPassword
