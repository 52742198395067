import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import Alert from "../utils/Alert";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";

const Signup = () => {
  const [userName, setUserName] = useState("");
  const [userEmailId, setUserEmailId] = useState("");
  const [organisationName, setOrganisationName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [nameError, setNameError] = useState("");
  const [orgError, setOrgError] = useState("");
  const [phone, setPhone] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  // const [error, setError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const hostname = window.location.hostname;

    const parts = hostname.split(".");
    if (
      parts.length > 2 &&
      parts[1] === "dashboard" &&
      parts[2] === "triumphiq"
    ) {
      navigate("/login");
    }
  }, []);

  const handlePhoneChange = (value, data) => {
    setPhone(value);
    setCountryCode(data.dialCode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userName.trim().length < 3) {
      setNameError("Please enter a valid Name");
      return;
    }

    if (organisationName.trim().length < 3) {
      setOrgError("Organisation name should be of minimum 3 letters");
      return;
    }

    setNameError("");
    setOrgError("");

    try {
      const response = await fetch("https://be.triumphiq.com/organisation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName,
          userEmailId,
          userPhoneNumberCountryCode: countryCode,
          userPhoneNumber: phone,
          organisationName,
        }),
      });

      if (response.status === 400) {
        const responseData = await response.text();

        if (responseData === "ORGANISATION_ALREADY_EXISTS") {
          alert("Organisation already exists!");
        } else if (responseData === "USER_ALREADY_EXISTS") {
          alert("User already exists!");
        }
      }

      const data = await response.json();
      if (response.ok) {

        const isLocalhost = window.location.hostname === "localhost";
        const cookieDomain = isLocalhost ? "localhost" : "dashboard.triumphiq.com";
        document.cookie = `authToken=${data.token}; path=/; domain=${cookieDomain}; secure; samesite=Strict`;

        // window.location.href = `${data.organisationUrl}/onboard?token=${data.token}`;
        window.location.href = `${data.organisationUrl}/onboard`;
        // window.location.href = `/onboard`;
      } else {
        console.error("Error:", data);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <div className="w-full h-screen p-20 flex gap-4 bg-gray-50">
      <div className=" h-[600px] w-[50%] mt-7 mx-5">
        <div className="">
          {/* {error && <Alert message={error} />} */}
          <h2 className="text-[24px] font-bold">Get started Now</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="mt-4">
              <h3 className="text-sm font-semibold">Name</h3>
              <input
                id="name"
                name="name"
                type="text"
                required
                placeholder="John Snow"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
              />
              {nameError && (
                <p className="text-red-500 text-xs mt-1">{nameError}</p>
              )}
            </div>
            <div>
              <p className="text-sm font-semibold">Work Email</p>
              <input
                id="email"
                name="email"
                type="email"
                required
                placeholder="work@company.com"
                value={userEmailId}
                onChange={(e) => setUserEmailId(e.target.value)}
                className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
              />
            </div>
            <div>
              <p className="text-sm font-semibold">Phone number</p>
              <PhoneInput
                country={"us"}
                value={phone}
                onChange={handlePhoneChange}
                enableSearch={true}
                placeholder="+17737021000"
                className="  mt-1 "
                dropdownStyle={{
                  width: "900%",
                }}
              />
            </div>
            <div>
              <p className="text-sm font-semibold">Organisation Name</p>
              <input
                id="text"
                name="text"
                type="text"
                required
                placeholder="url here"
                value={organisationName}
                onChange={(e) => setOrganisationName(e.target.value)}
                className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
              />
              {orgError && (
                <p className="text-red-500 text-xs mt-1">{orgError}</p>
              )}
            </div>
            <div className="flex gap-1">
              <input
                type="checkbox"
                className="cursor-pointer"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <p className="text-xs">
                I agree to Terms of Service and Privacy Policy
              </p>
            </div>
            <button
              type="submit"
              disabled={!isChecked} // Button is disabled if checkbox is not checked
              className={`w-[500px] h-12 rounded-full flex items-center justify-center ${
                isChecked
                  ? "bg-blue-700 text-white"
                  : "bg-gray-400 text-gray-200 cursor-not-allowed"
              }`}
            >
              Signup for Free
            </button>
          </form>
          {/* <p className="text-xs mt-4">
            Have an account?{" "}
            <Link to="/login" className="text-blue-600 underline">
              Sign in
            </Link>
          </p> */}
        </div>
      </div>
      <div className="h-[550px] my-auto w-[50%] bg-blue-600 rounded-2xl">
        {/* content  */}
        <div className="p-8 px-12 text-white">
          <h2 className="text-[24px] font-bold w-[350px] ">
            The simplest way to manage your Customers
          </h2>
          <p className="text-sm text-gray-200 w-[260px] mt-3">
            Know your customer better. Reduce Churn. Grow your Cusomters
          </p>
          {/* <div className='mt-10'>
       <Carousel/>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Signup;
