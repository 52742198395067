import React from 'react'
import AccountDetails from './AccountDetails'
import AccountTeams from './AccountTeams'
import { MdArrowBackIosNew } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Sidebar from '../../container/Sidebar'
import { IoArrowBackCircleOutline } from 'react-icons/io5'

const AccountInfo = () => {
  return (
    <Sidebar>
        <div className='bg-white pt-[24px] px-[8px] h-screen'>
   <div className="flex gap-2 items-center mb-2 ml-[16px]">
          <Link to="/settings">
            <IoArrowBackCircleOutline size={24} />
          </Link>
          <h2 className="text-2xl ">Account Information</h2>
        </div>
     <AccountDetails/>
     <AccountTeams/> 
     </div>
    </Sidebar>
  )
}

export default AccountInfo
