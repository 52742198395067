import React from 'react'
import Sidebar from '../container/Sidebar'

const Success = () => {
  return (
    <Sidebar>
      <div>
        vbkzdkc
      </div>
    </Sidebar>
  )
}

export default Success
