import React from 'react'

const Notes = () => {
  return (
    <div className='h-screen w-full bg-red-300' id="Notes">
      
    </div>
  )
}

export default Notes 