import React, { useEffect, useState } from "react";
import Settingbar from "../../container/Settingbar";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { IoCloseCircleSharp, IoSearch } from "react-icons/io5";
import { BiSearchAlt } from "react-icons/bi";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const AccountDetails = () => {
  const [data, setData] = useState(null);
  const [editedData, setEditedData]= useState(null);
  const [addField, setAddField] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [editDropdown, setEditDropdown]= useState(false);
  const [isDropdown, setIsDropdown] = useState(true);
  const [possibleValues, setPossibleValues] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
   const [fieldToDelete, setFieldToDelete] = useState(null);
   const [isEditOpen, setIsEditOpen] = useState(false);
   const [editField, setEditField] = useState({ fieldName: '', fieldType: '', possibleValues:null, index: null });
   const [visibilityFields, setVisibilityFields] = useState(null);
   const [visibilityChanged, setVisibilityChanged] = useState(false);
   const [showAll, setShowAll] = useState(false);
   const [searchQuery, setSearchQuery] = useState("")

          /* 1. Api calls */
  // get all acc details 
  const fetchData = async () => {
    const token = localStorage.getItem("authToken") || "eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiJdLCJzdWIiOiJmcmVzcHJlc3NvLmtzZ0BnbWFpbC5jb20iLCJpYXQiOjE3MzI4OTA5MzgsImV4cCI6MTczMjg5MjEzOH0.viPj5JuaiDYxTYlMu4zgYgiT1Eb_o6whBhqgQMS5d-E";
    try {
      const response = await fetch(
        `https://be.triumphiq.com/fields?organisationCname=${
          window.location.hostname.split(".")[0]
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("successfully fetched data");
        setData(data);
        console.log("object", data);
      }
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //add field api
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://be.triumphiq.com/fields?organisationCname=${
          window.location.hostname.split(".")[0]
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({
            ...data,
            companyFields: [
              ...data.companyFields,
              {
                fieldName,
                fieldType,
                visibility: true,
                isDefaultField: false,
                possibleValues
              }
            ]
          }),
        }
      );
      if (response.ok) {
        setAddField(!addField);
        alert("Success");
        setFieldName("");
        setFieldType("");
        setPossibleValues([]);
        fetchData();
      } else {
        console.error("Failed", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //delete field api
  const handleOnDelete = async (updatedArray) => {
    try {
      const response = await fetch(
        `https://be.triumphiq.com/fields?organisationCname=${
          window.location.hostname.split(".")[0]
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify(updatedArray),
        }
      );
      if (response.ok) {
        alert("Success");
        fetchData();
      } else {
        console.error("Failed", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // edit field
  const handleOnEdit = async (updatedFields) => {
    try {
      const response = await fetch(
        `https://be.triumphiq.com/fields?organisationCname=${
          window.location.hostname.split(".")[0]
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify(
            updatedFields
          ),
        }
      );
      if (response.ok) {
        alert("Success");
        fetchData();
      } else {
        console.error("Failed", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // api for visibility
  const handleSaveChanges = async () => {
    try {
      const response = await fetch(
        `https://be.triumphiq.com/fields?organisationCname=${
          window.location.hostname.split(".")[0]
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify(
           visibilityFields
          ),
        }
      );

      if (response.ok) {
        alert("Visibility changes saved successfully!");
        setVisibilityChanged(false);
        fetchData();
      } else {
        console.error("Failed to save changes", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  // add field button handler
  const handleOnclick = () => {
    setAddField(!addField);
  };

  // dropdown input splitter
  const handleInputChange = (e) => {
    const values = e.target.value.split(",").map(v => v.trim());
    setPossibleValues(values);
    console.log(values)
  };

// delete filtering on confirmation delete
  const handleDelete = () => {
    // Filter out the field with the specific index
    if (fieldToDelete !== null) {
    const updatedFields = data.companyFields.filter(
      (field,index) => index !== fieldToDelete
    );

    const updatedArray = ({
      ...data,
      companyFields: updatedFields,
    });

    handleOnDelete(updatedArray);
  }
    setShowPopup(false);
    setFieldToDelete(null);
  };

  // delete button on users
  const confirmDelete = (index) => {
    setFieldToDelete(index);
    setShowPopup(true); 
  };
 
  //cancelling the delete
  const cancelDelete = () => {
    setShowPopup(false); 
    setFieldToDelete(null); 
  };

  // save on edit popup
  const handleSave = () => {
    const updatedFields = { ...data };
    updatedFields.companyFields[editField.index] = editField;
    setEditDropdown(false);
    setEditedData(updatedFields); // Update the state with the new fields array
    console.log("edited state",editedData);
    console.log("edited const",updatedFields);
    setIsEditOpen(false); 
    handleOnEdit(updatedFields);
  };

  // edit button click
  const handleEditClick = (index) => {
    setEditField({ ...data?.companyFields[index], index });
    setIsEditOpen(true);
  };

  //visiblity function
  const handleToggleVisibility = (index) => {
    const updatedFields = { ...data };
    updatedFields.companyFields[index].visibility =
      !updatedFields.companyFields[index].visibility;
    setVisibilityFields(updatedFields);
    setVisibilityChanged(true);
  };

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  const filteredFields = data?.companyFields?.filter((field) =>
    field.fieldName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="container mx-auto p-4 relative">
        {/* <div className="flex gap-2 items-center mb-2">
          <Link to="/settings">
            <MdArrowBackIosNew size={20} />
          </Link>
          <h2 className="text-xl font-semibold ">Account Details</h2>
        </div> */}
        <div className="bg-white  rounded-md shadow-md border-gray-200 border mb-2">
          <div className="h-14 w-full  flex justify-between items-center px-4 border-b-2">
            <h2 className="text-base px-3">Account Details</h2>
            
            <div className="flex items-center gap-4 relative">
            <div className="mb-1">
            <BiSearchAlt color="gray"
            className="absolute top-2 left-1" />

              <input
                type="text"
                placeholder="Search by field name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border border-gray-300 rounded-md py-1 px-6 w-64 text-sm"
              />
            </div>
            {visibilityChanged && (
              <div className="">
                <button
                  className="bg-blue-500 h-8 w-32 text-white rounded-md text-sm"
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </button>
              </div>
            )}
            <button
              className="bg-blue-500 h-8 w-32 text-white rounded-md text-sm"
              onClick={handleOnclick}
            >
              Add Fields
            </button>
            </div>
          </div>
          <div className="p-4">
          
            <table className="min-w-full bg-white ">
              <thead>
                <tr className="font-semibold">
                  <th className="py-2 px-3 text-left text-sm text-gray-600 w-[500px]">
                    Field Name
                  </th>
                  <th className="py-2 text-left text-sm text-gray-600  ">
                    Type
                  </th>
                  <th className="py-2 text-left text-sm text-gray-600 w-[180px]">
                    Action
                  </th>
                  <th className="py-2 text-left text-sm text-gray-600 w-[200px]">
                    Visibility
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredFields?.slice(0, showAll ? filteredFields.length : 3).map((field, index) => (
                  <tr key={index}>
                    <td className="py-2 px-3 text-sm text-gray-700 ">
                      {field?.fieldName}
                    </td>
                    <td className="py-2 text-sm text-gray-700 capitalize ">
                    {field.fieldType==="ENUM"? <p>Dropdown</p> : field.fieldType.toLowerCase() }
                    </td>
                    <td className="py-2 text-sm text-gray-700 ">
              <button className="text-blue-500 mr-2" onClick={() => handleEditClick(index)}>Edit</button>       
                    {index>2?
                      <button className="text-red-500"   onClick={() => confirmDelete(index)} // Show confirmation modal
                        >Delete</button>
                      :null}
                    </td>
                    <td className="py-2 text-sm text-gray-700">
                    {index < 3 ? (
                      <span className="text-green-500 font-semibold">ON</span>
                    ) : (
                      <div className="relative inline-block w-12 h-6 align-middle select-none transition duration-200 ease-in">
                        <input
                          type="checkbox"
                          name={`toggle-${index}`}
                          id={`toggle-${index}`}
                          checked={field.visibility} // Set the toggle based on visibility
                          onChange={() => handleToggleVisibility(index)} // Toggle on change
                          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition-transform duration-300 ease-in-out"
                          style={{
                            transform: field.visibility
                              ? "translateX(1.5rem)"
                              : "translateX(0)",
                          }}
                        />
                        <label
                          htmlFor={`toggle-${index}`}
                          className={`toggle-label block overflow-hidden h-6 rounded-full ${
                            field.visibility ? "bg-green-500" : "bg-gray-300"
                          } cursor-pointer transition-colors duration-300`}
                        ></label>
                      </div>
                    )}
                  </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data?.companyFields?.length > 3 && (
              <div className="text-center mt-4 flex justify-center">
                <button
                  className="text-sm underline text-blue-500 px-3 flex items-center gap-1 py-1 rounded-md"
                  onClick={handleShowMore}
                >
                  {showAll ?  <><span>Show less</span> <FaArrowUp size={12} /> </>: <><span>Show more</span> <FaArrowDown size={12} className="mt-[1px]"/></>}
                </button>
              </div>
            )}
          </div>
        </div>
        {/* delete popup  */}
        {showPopup && (
        <div className="fixed inset-0 flex  z-50 items-center justify-center shadow bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg h-[150px] w-[450px] border-2 border-gray-500">
            <h2 className="text-md  mb-6 text-center">
              Are you sure you want to delete this field?
            </h2>
            <div className="flex justify-around space-x-4 pt-4">
              <button
                className="bg-red-500 text-white py-1 px-6 rounded text-sm"
                onClick={handleDelete} // Confirm deletion
              >
                Delete
              </button>
              <button
                className="bg-gray-500 text-white py-1 px-6 rounded text-sm"
                onClick={cancelDelete} // Cancel deletion
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {/* add field popup  */}

        <div
          className={`absolute ${
            addField ? "block" : "hidden"
          }  inset-0 flex items-center justify-center`}
        >
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div class="border-2 border-gray-500 bg-white rounded-md p-4 h-[250px] w-[600px]">
            <IoCloseCircleSharp
              size={24}
              onClick={handleOnclick}
              className="cursor-pointer float-end"
            />
            <h2 className="text-center font-semibold pt-4">Create a New Account Field</h2>
            <form action="" onSubmit={handleOnSubmit}>
              <div className="flex flex-wrap justify-around p-2 pt-6 text-sm">
                <div className="w-24 flex flex-col justify-center items-center">
                  <h2 className="mb-4">Field Name</h2>
                  <input
                    type="text"
                    className="border-2 w-48 rounded-md h-8  px-1 border-gray-300 focus:outline-gray-400"
                    placeholder="Enter name"
                    required
                    value={fieldName}
                    onChange={(e) => setFieldName(e.target.value)}
                  />
                </div>
                <div className="w-24 flex flex-col justify-center items-center">
                  <h2 className="mb-4">Field Type</h2>
                  <select
                    name="fieldType"
                    id="fieldType"
                    className="w-48 border-2 h-8 border-gray-300 focus:outline-gray-400 rounded-md text-gray-400 "
                    value={fieldType}
                    onChange={(e) => setFieldType(e.target.value)}
                  >
                    <option value="" className="text-gray-400" disabled>
                      Select{" "}
                    </option>
                    <option value="TEXT" className="text-black">Text</option>
                    <option value="NUMBER" className="text-black">Number</option>
                    <option value="ENUM" className="text-black">Dropdown</option>
                  </select>
                </div>
              </div>
              <div className="p-4 mt-2 flex">
                <div className="w-[50%] flex justify-center ">
                  {/* {fieldType==="ENUM"?
      <label className="flex items-center space-x-3">
        <input
          type="radio"
          className="form-radio h-3 w-3 text-blue-600"
          checked={isDropdown}
          onClick={() => setIsDropdown(!isDropdown)}
        />
        <span className="text-gray-700 text-sm">Is this a dropdown?</span>
      </label> :null} */}

      {(isDropdown && fieldType==="ENUM") && (
        <div className="">
          <input
            type="text"
            required 
            className="w-64 text-sm ml-12 px-2 h-8 border-2 border-gray-300 focus:outline-gray-400 rounded-md "
            placeholder="Please enter values, comma seprated"
            onChange={handleInputChange}
          />
        </div>
      )}
      </div>
              <div className="flex justify-end px-8 mb-4 w-[50%]">
                <button
                  className="flex bg-blue-500 px-8 items-center text-white rounded-md h-8"
                  type="submit"
                >
                  Save
                </button>
              </div>
    </div>
              
            </form>
          </div>
          </div>
        </div>
        {/* edit field popup  */}
        {isEditOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg  h-[250px] w-[600px] relative border-2 border-gray-500">
               <IoCloseCircleSharp
              size={24}
              onClick={() => {setIsEditOpen(false);setEditDropdown(false);}}
              className="cursor-pointer float-end"
            />
            <h2 className="text-md font-semibold mb-4 mt-4 text-center">Edit Field</h2>
    
            <div className="flex gap-4 justify-around">
            <div className="mb-4 text-sm">
              <label className="block text-sm text-gray-800 mb-4 text-center">Field Name</label>
              <input
                type="text"
                value={editField.fieldName}
                onChange={(e) => setEditField({ ...editField, fieldName: e.target.value })}
                className="border-2 border-gray-300 rounded-md w-[185px] p-1 text-sm focus:outline-gray-400  "
              />
            </div>
            <div className="mb-4 ">
              <label className="block text-sm text-gray-800  text-center mb-4">Field Type</label>
               <select
               type="text"
                    className="w-48 border-2 h-8 px-1 border-gray-300 rounded-md text-sm focus:outline-gray-400"
                    value={editField.fieldType}
                    onChange={(e) => setEditField({ ...editField, fieldType: e.target.value })}
                  >
                    <option value="" disabled>
                      Select{" "}
                    </option>
                    <option value="TEXT">Text</option>
                    <option value="NUMBER">Number</option>
                    <option value="ENUM">Dropdown</option>
                  </select>
            </div>
            </div>
              {/* for dropdown values  */}
              {editField.fieldType==='ENUM' && editField.possibleValues!==null?
                <div className="mb-4 ml-10">
                <label className="block text-sm text-gray-800 mb-1 font-medium">
                Dropdown Values
              </label>
               <input
                 type="text"
                   className="border-2 border-gray-300 rounded w-64 focus:outline-gray-400  p-1 text-sm"
                 placeholder="Please enter values, comma seprated"
                 value={editField.possibleValues}
                 onChange={(e) =>
                  setEditField({
                    ...editField,
                    possibleValues: e.target.value.split(",").map((value) => value.trim()),
                  })
                }
               />
             </div>
              : null  }

              {editField.fieldType==='ENUM'&&  editField.possibleValues===null?
              <div className="mb-4 mt-6">
              {/* <label className="flex items-center space-x-3 mb-1">
              <input
                type="radio"
                className="form-radio h-3 w-3 text-blue-600"
                checked={editDropdown}
                onClick={() => setEditDropdown(!editDropdown)}
              />
              <span className="text-gray-700 text-sm">Is this a dropdown?</span>
            </label> */}
           
             <input
             type="text"
               className="border-2 focus:outline-gray-400 border-gray-300 rounded w-64 ml-10 p-1 text-sm"
             placeholder="Please enter values, comma seprated"
             value={editField.possibleValues}
             required 
             onChange={(e) =>
              setEditField({
                ...editField,
                possibleValues: e.target.value.split(",").map((value) => value.trim()),
              })
            }
           />
        
           </div>
          :null}
            {/* <div className="flex justify-end text-sm"> */}
              <button
                className="bg-blue-500 text-white px-8 py-1 rounded mr-2 absolute bottom-[40px] right-[52px]"
                onClick={handleSave}
              >
                Save
              </button>
             
            {/* </div>  */}
          </div>
        </div>
      )}
      </div>
    </>
  );
};

export default AccountDetails;
